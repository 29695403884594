import React, { useContext, useEffect, useRef, useState } from 'react';
import { UseContextClues } from '../../context/ContextClues';
import { UseToggleContext } from '../../context/ToggleContexts';
import Loader from '../Loader/Loader';
import { optimizeCrystalBallResults } from '../../utils/crystalBall';
import ReabateOp from '../SVG/RebateOp';
import CrystalBallSharesTables from '../Tables/CrystalBallSharesTables';
import CrystalBallControls from '../Controls/CrystalBallControls';
import CrystalBallTable from '../Tables/CrystalBallTable';
import CrystalBreakevenTable from '../Tables/CrystalBreakevenTable';
import HoverTPN from '../Hover/HoverTPN';
import Logo259 from '../SVG/Logo259';
import LockedMessage from '../Error/LockedMessage';

export default function CrystalBallSection() {

    const tableRef = useRef(null);
    
    const context = UseContextClues();
    const toggler = UseToggleContext();

    const [ hovered, setHovered ] = useState(false);
    const [ payload, setPayload ] = useState([]);
    const [ breakEvenGrid, setBreakEven ] = useState(null);
    const [ breakEvenBool, setBreakEvenBool ] = useState(false);
    const [ shift, setShift ] = useState(0.0);
    const [ loadingOptimize, setLoadOpt ] = useState(false);
    const [ calculation, setCalc ] = useState({
        current : null
    });

    useEffect(() => {
        if (context.crystal?.grid.length>0) {

            let obj = {};
            context.crystal.grid.forEach(drug => {

                if (obj[drug.mfr] == undefined) {
                    obj[drug.mfr] = {};
                    obj[drug.mfr][drug.drugname.split(' ').join('_')] = {
                        drugname: drug.drugname,
                        rate: drug.rate,
                        tpn: drug.tpn,
                        starting_rate: drug.rate,
                    };
                    
                } else {
                    if (obj[drug.mfr][drug?.drugname.split(' ').join('_')] == undefined) {
                        obj[drug.mfr][drug.drugname.split(' ').join('_')] = {
                            drugname: drug.drugname,
                            rate: drug.rate,
                            tpn: drug.tpn,
                            starting_rate: drug.rate,
                        };
                    } else {
                        console.log('something is wrong')
                    };
                };

            });

            setPayload(obj);
        };
    }, [context.crystal]);

    useEffect(() => {
        findSum(context.crystal?.grid, "current");
    }, [context.crystal]);

    useEffect(() => {
        findSum(breakEvenGrid?.grid, "breakeven")
    }, [breakEvenGrid]);
    
    useEffect(() => {
        setBreakEven(null);
        setBreakEvenBool(false);
    }, [context.crystal]);
    
    useEffect(() => {
        setShift('')
    }, [context.crystal, breakEvenBool]);

    function findSum(grid, type){
        let dict = {
            wac: 0,
            rebate: 0,
            netCost: 0,
        };
        if (grid?.length>0) {
            grid.forEach(drug => {
                dict.wac = dict.wac + drug["WAC"]
                dict.rebate = dict.rebate + drug["rebates"]
            });
            dict.netCost = dict.wac - dict.rebate; 
        };
        setCalc({
            ...calculation,
            [type]: dict
        });
        dict = {};
    };
    
    function handleSubmit(e) {
        e.preventDefault();
        
        let sendPay = [];
        
        Object.keys(payload).map(mfr => {
            Object.entries(payload[mfr]).forEach(drug => {
                sendPay.push(drug[1]);
            });
        });

        optimizeCrystalBallResults(sendPay, breakEvenBool, setBreakEven, context, setLoadOpt, shift/100, context.casenumber, toggler.setStatus);
    };

    return (
        <>
            {/* <!-- Grid to display results --> */}
            <form onSubmit={handleSubmit} style={{height: toggler.pageHeight-100}} className={`flex flex-col p-4 ${toggler.pageWidth>1280?"pr-10":"pr-4"} border row-span-2 transition-all duration-700 ${toggler.toggle?.breakEven&&"col-span-2"}`}>
                <div className='text-xl font-medium pb-2 whitespace-nowrap flex justify-between items-center'>Crystal Ball Category Forecast 
                    <CrystalBallControls {...{tableRef, breakEvenGrid, breakEvenBool, setShift, setBreakEvenBool, loadingOptimize, shift}} />
                </div>
                {context.crystal&&<div>
                    <LockedMessage />
                </div>}

                {context.crystal?
                    <div className={`${toggler.toggle?.breakEven?"grid grid-rows-[75%,25%] overflow-y-hidden":"overflow-y-auto"}  transition-all duration-700 h-full`}>    
                        <div className={` ${toggler.toggle?.breakEven?" overflow-y-auto":""} flex h-inherit pr-4`}>
                            <HoverTPN 
                                addDrop={!breakEvenGrid} 
                                top="right-[345px]" 
                                right={"z-[300]"} 
                                bottom="right-[45px]" 
                                hovered={hovered} 
                                alternativeBot={"absolute bottom-[-16px] left-[375px] w-3 overflow-hidden mx-auto rotate-[270deg]"} alternativeTop={"bg-primary-blue text-white p-4 rounded-lg font-medium mb-[-3px] shadow-xl"} 
                            />
                            <CrystalBallTable {...{tableRef, setHovered, setPayload, payload, breakEvenBool}} />
                            {/* // this is break even data */}
                            {toggler.toggle?.breakEven&&breakEvenGrid&&<CrystalBreakevenTable {...{ breakEvenGrid, payload }} />}           
                        </div>
                        <CrystalBallSharesTables {...{calculation, breakEvenGrid, setPayload, payload, breakEvenBool}} />
                    </div>
                :
                <div className='border-2 relative rounded' style={{height: toggler.pageHeight-198+"px"} }>
                    <div style={{height: toggler.toggle?.breakEven?toggler.pageHeight-201+"px":toggler.pageHeight-198+"px"} } className="w-full rounded opacity-[0.1] flex flex-col justify-center absolute top-0 bottom-0 cover bg-primary-dark m-auto">
                        <div className='flex items-center justify-center'>
                            {window.location.href.toLowerCase().includes("app.rebateoptimizer.com")?<ReabateOp switchColors={"#cecece"} scale={"scale(0.5)"} className={""}/>:<Logo259 />}
                        </div>
                    </div>
                    <Loader textContent="Please choose a category in the Crystal Ball section" loading={toggler.loader == "crystal"} addClass={"font-extrabold"} />
                </div>}
            </form>
        </>
    );
};