import React, {useState, useContext, useRef, useEffect} from "react"
import FormButton from "../Buttons/FormButton";
import { loadAssumptions, saveAssumptionsButton } from "../../utils/assumptions";
import CollapsibleButton from "../Buttons/CollapsibleButton";
import { UseContextClues } from '../../context/ContextClues';
import { UseToggleContext } from '../../context/ToggleContexts';
import { handleToggle } from "../../utils/globalHandlers";
import AssumptionTable from "../Tables/AssumptionTable";

export default function Assumptions() {

    const id = "assumptions";
        
    const assumRef = useRef();
    const context = UseContextClues();
    const toggler = UseToggleContext();

    const [ bool, setBool ] = useState(false);

    useEffect(() => {
        if (context.exclusionSettings.assumptions.length === 0) {
            loadAssumptions(context);
        }
    }, [context.exclusionSettings.assumptions]);
    
    function handleCLick(){
        saveAssumptionsButton(context, assumRef.current, toggler)
    };

    return (
        <div id={id}>
            <CollapsibleButton useName={id} useValue={toggler.collapsible[id]} onClick={e => handleToggle(e, toggler)} buttonTitle={id} chevron={toggler?.collapsible[id]} addClasses={"z-25"} />
            <div className={`px-4 flex flex-col transition-all duration-700 text-xs border-b border-primary-dark ${toggler.collapsible[id] ? "opacity-1 translate-y-[0] pb-3 max-h-[334px]" : "overflow-hidden max-h-0 opacity-0 "}`} >
                <div className="pt-2">
                   <AssumptionTable {...{ reference:assumRef, dataContext: context?.exclusionSettings?.assumptions, bool, setBool }} />
                </div>
                {toggler.collapsible[id]&&<FormButton onClick={handleCLick} buttonId={"saveAssumptionsButton"} buttonText={"Save Changes"} />}
            </div>
        </div>
    )
} 