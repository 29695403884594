import { useContext, useEffect, useRef } from "react";
import Analytics from "../Analytics/Analytics";
import CrystalBallSection from "../CrystalBall/CrystalBallSection";
import Events from '../Events/Events';
import KpiPanel from "../KpiPanel/KpiPanel";
import Rates from "../Rates/Rates";
import ResultSummarySection from "../ResultsSummary/ResultSummarySection";
import StatusBox from "../StatusBox/StatusBox";
import UserGuide from "../UserGuide/UserGuide";
import { UseToggleContext } from '../../context/ToggleContexts';
import UserContext, { UseUserContext } from '../../context/UserContext';
import PricingLensPanel from "../PricingLens/PricingLensPanel";
import CaseManager from "../../pages/CaseManager";

export default function MainSection({aux}) {

    const mainPageRef = useRef(null);

    const account = UseUserContext();
    const toggler = UseToggleContext();

    useEffect(() => {
        if (mainPageRef&&mainPageRef.current) {
            mainPageRef.current.addEventListener("click", () => {
                toggler.switchAux("")
            })
        }
    }, []);

    return (
        <>
            <div style={{width: toggler.pageWidth, height: toggler.pageHeight}} ref={mainPageRef} className={` grid pb-24 ${(account.user.userData.user_type==="admin"||account.user.userData.user_type==="superadmin"||account.user.userData.user_type==="trade_user"||account.user.userData.user_type==="client_mgr")?"flex xl:grid-cols-[1fr,1fr] pr-8 xl:pr-0":"pr-10"} xl:grid-rows-[60%,40%] overflow-x-auto bg-background-light`}>
                <KpiPanel />
                {(account.user.userData.user_type==="admin"||account.user.userData.user_type==="superadmin"||account.user.userData.user_type==="trade_user"||account.user.userData.user_type==="client_mgr")&&<CrystalBallSection />}
                <UserGuide />
            </div>
            <StatusBox />
            {(account.user.userData.user_type==="admin"||account.user.userData.user_type==="superadmin"||account.user.userData.user_type==="trade_user"||account.user.userData.user_type==="client_mgr")&&
                <>
                    <Rates />
                    <Events />
                </>
            }
            <ResultSummarySection />
            {/* <CaseManager /> */}
            <Analytics />
            <PricingLensPanel />
        </>
    )
}