import React from "react"
import ClaimsControls from "./ClaimsControls"
import Spec from "./Spec"
import Assumptions from "./Assumptions"
import FormularySelect from "./FormularySelect"
import ContractDefintion from "./ContractDefinition"
import CrystalBall from "./CrystalBall"
import { UseToggleContext } from '../../context/ToggleContexts';
import { UseUserContext } from '../../context/UserContext';

export default function SidePanel({}) {

    const account = UseUserContext();
    const toggler = UseToggleContext();

    return (
        <>   
            <div style={{backgroundImage: ""}} className="relative box-border">
                <div style={{height: toggler.pageHeight-100 }} className="border w-[20rem] box-border overflow-y-auto ">
                    <Spec />
                    <ClaimsControls />
                    <Assumptions />
                    <FormularySelect />
                    <ContractDefintion />
                    {(account.user.userData?.user_type==="admin"||account.user.userData?.user_type==="superadmin"||account.user.userData?.user_type==="trade_user"||account.user.userData?.user_type==="client_mgr")&&<CrystalBall />}
                </div>
            </div>  
        </>
    )
}