import {extractFromLocal} from "./loadSave";

export function runStandard ({year1StartDateValue, parameters, selectedFormularyId, selectedStartDateValue, selectedEndDateValue}, context, toggler) {
    toggler.setStatus("Calculating Projections, please wait...");
    toggler.setLoader("results");
    
    // Encoding parameters as a URI component
    // var parametersParam = encodeURIComponent(JSON.stringify(parameters));


    // Check for missing values
    if (!selectedStartDateValue || !selectedEndDateValue || !year1StartDateValue || !selectedFormularyId) {
        // alert("Please make sure all required fields are filled in.");
        toggler.setStatus("An error ha occured, you may need to select a base formulary to render results.");
        setTimeout(() => {
            toggler.setLoader("");
            toggler.setStatus("");
        }, 2500);
        return;  // Exit the function early
    }

    var selectedStartDateObj = new Date(selectedStartDateValue);
    var selectedEndDateObj = new Date(selectedEndDateValue);

    // Compute the midpoint date for the selected start and end dates
    var base_midpointTimestamp = selectedStartDateObj.getTime() + (selectedEndDateObj - selectedStartDateObj) / 2;
    var base_midpoint = dateToDaysSinceEpoch(new Date(base_midpointTimestamp));

    var year1StartDateObj = new Date(year1StartDateValue);
    year1StartDateObj.setDate(year1StartDateObj.getDate() + 180);  // add 180 days
    var yr1midpoint = dateToDaysSinceEpoch(year1StartDateObj);

    function dateToDaysSinceEpoch(dateObj) {
        // Convert milliseconds since Epoch to days since Epoch
        return Math.floor(dateObj.getTime() / (1000 * 60 * 60 * 24));
    }
    var annualizationFactor = 365 / (selectedEndDateObj - selectedStartDateObj) * (1000 * 60 * 60 * 24);
    annualizationFactor = parseFloat(annualizationFactor.toFixed(3));
    // console.log(`Fetching data from endpoint: /api/run_standard?formularyId=${selectedFormularyId}&base_midpoint=${base_midpoint}&yr1midpoint=${yr1midpoint}&annualization=${annualizationFactor}`);
    
    var requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json',
        Authorization: `Bearer ${extractFromLocal().accessToken}`,
    },
        body: JSON.stringify(parameters)
    };

    var url = `/api/run_standard?formularyId=${selectedFormularyId}&base_midpoint=${base_midpoint}&yr1midpoint=${yr1midpoint}&annualization=${annualizationFactor}&casenumber=${context.casenumber}`;
    // Make the fetch call using the requestOptions
    fetch(url, requestOptions)
        .then(response => {
            if (!response.ok) {
                return response.text().then(text => { throw new Error(text) });
            }
            return response.json();
        })
        .then(data => {
            context.setResults(data);
            runTarget({
                year1StartDateValue: context.settings.year.year1,
                selectedFormularyId: context.settings.formulary.targetFormulary,
                selectedStartDateValue: context.settings.claims.startDate,
                selectedEndDateValue: context.settings.claims.endDate,
                parameters: context.exclusionSettings,
            }, context, toggler)
        })
        .catch(error => {
            console.error('Error:', error);
            toggler.setStatus("An error occurred. Please try again.");
            setTimeout(() => {
                toggler.setStatus("");
            }, 500);
            // statusBar.textContent = "An error occurred. Please try again.";
        });
};

// still working on the below - paul

// document.getElementById('runStandardButtonBeta').addEventListener('click', function() {
//     var statusBar = document.getElementById("statusBar");
//     statusBar.textContent = "Calculating Projections, please wait...";
//     statusBar.style.display = "block";  // Make the status bar visible

//     var annualizationFactor = (365 / (selectedEndDateObj - selectedStartDateObj) * (1000 * 60 * 60 * 24)).toFixed(3);
//     console.log(`Fetching data from endpoint: /run_standard?formularyId=${selectedFormularyId}&base_midpoint=${base_midpoint}&yr1midpoint=${yr1midpoint}&annualization=${annualizationFactor}`);
    
//     fetch(`/run_standard?formularyId=${selectedFormularyId}&base_midpoint=${base_midpoint}&yr1midpoint=${yr1midpoint}&annualization=${annualizationFactor}`)
//     .then(response => response.json())
//     .then(data => {
//         // Parse the data
//         var perBrandEstimates = transformData(JSON.parse(data['Per Brand Estimates']));
//         var rxSummary = transformData(JSON.parse(data['Rx_summary']));
//         var rebatesSummary = transformData(JSON.parse(data['Rebates_summary']));
//         insertDataIntoTable('perBrandEstimatesTable', perBrandEstimates, formatPerBrandEstimates);
//         insertDataIntoTable('rxSummaryTable', rxSummary, formatDecimal);
//         insertDataIntoTable('rebatesSummaryTable', rebatesSummary, formatCurrencyExp);

//         // After handling run_standard data, fetch the top_20_drugs:
//         return fetch('/top_20_drugs');
//     })
//     .then(response => response.json())
//     .then(data => {
//         // Handle the top_20_drugs data
//         fetchAndFillTable('top20DrugsTable', '/top_20_drugs?scenario=target', top20DrugsHeaders, formatCurrency);

//         // Then fetch the top_20_categories:
//         return fetch('/top_20_categories');
//     })
//     .then(response => response.json())
//     .then(data => {
//         // Handle the top_20_categories data
//         fetchAndFillTable('top20CategoriesTable', '/top_20_categories', top20CategoriesHeaders, formatCurrency);

//         // Finally, fetch the highest_products_no_rebates:
//         return fetch('/highest_products_no_rebates');
//     })
//     .then(response => response.json())
//     .then(data => {
//         // Handle the highest_products_no_rebates data
//         fetchAndFillTable('highestProductsNoRebatesTable', '/highest_products_no_rebates', highestProductsNoRebatesHeaders, formatCurrency);
//         statusBar.style.display = "none"; 
//     })
//     .catch(error => {
//         console.error('Error:', error);
//         statusBar.textContent = "An error occurred. Please try again.";
//     });
// });


export function runTarget(
    {year1StartDateValue,
    selectedFormularyId,
    selectedStartDateValue,
    selectedEndDateValue, 
    parameters}, context, toggler) {
 
    toggler.setStatus("Calculating Projections, please wait...");
    toggler.setLoader('target');

    // Encoding parameters as a URI component
    var parametersParam = encodeURIComponent(JSON.stringify(parameters));
    // Check for missing values
    if (!selectedStartDateValue || !selectedEndDateValue || !year1StartDateValue || !selectedFormularyId) {
        // alert("Please make sure all required fields are filled in.");
        toggler.setStatus("An error has occured, you may need to select a target formulary to render results.");
        setTimeout(() => {
            toggler.setLoader("");
            toggler.setStatus("");
        }, 5000);
        return;  // Exit the function early
    }

    var selectedStartDateObj = new Date(selectedStartDateValue);
    var selectedEndDateObj = new Date(selectedEndDateValue);

    // Compute the midpoint date for the selected start and end dates
    var base_midpointTimestamp = selectedStartDateObj.getTime() + (selectedEndDateObj - selectedStartDateObj) / 2;
    var base_midpoint = dateToDaysSinceEpoch(new Date(base_midpointTimestamp));

    var year1StartDateObj = new Date(year1StartDateValue);
    year1StartDateObj.setDate(year1StartDateObj.getDate() + 180);  // add 180 days
    var yr1midpoint = dateToDaysSinceEpoch(year1StartDateObj);

    function dateToDaysSinceEpoch(dateObj) {
        // Convert milliseconds since Epoch to days since Epoch
        return Math.floor(dateObj.getTime() / (1000 * 60 * 60 * 24));
    }
    var annualizationFactor = 365 / (selectedEndDateObj - selectedStartDateObj) * (1000 * 60 * 60 * 24);
    annualizationFactor = parseFloat(annualizationFactor.toFixed(3));
    var requestOptions = {
        method: 'POST',
        headers: { 'Content-Type' : 'application/json',
        Authorization: `Bearer ${extractFromLocal().accessToken}`,
     },
        body: JSON.stringify(parameters)
    };

    var url = `/api/run_target?formularyId=${selectedFormularyId}&base_midpoint=${base_midpoint}&yr1midpoint=${yr1midpoint}&annualization=${annualizationFactor}&casenumber=${context.casenumber}`;
    // Make the fetch call using the requestOptions
    fetch(url, requestOptions)
        .then(response => response.json())
        .then(data => {
            context.setTarget(data);
            context.setSettings({
                ...context.settings,
                locked: false,
                savedCase: false
            });
            toggler.setStatus("");
        })
        .catch(error => {
            console.error('Error:', error);
            toggler.setStatus("An error occurred. Please try again.");
            setTimeout(() => {
                toggler.setLoader("");
                toggler.setStatus("");
            }, 2500);
        });
};	


// ------------------------------------- helper functions ----------------------------------

export function transformData(data) {
    // console.log("Transforming data:", data);
    var transformedData = [];
    for (var year in data) {
        for (var channel in data[year]) {
            transformedData.push({
                Year: year,
                Channel: channel,
                Value: parseFloat(data[year][channel]) // convert the value to a number
            });
        }
    }
    // console.log("Transformed data:", transformedData); - must remove all console logs
    return transformedData;
};

export function convertYears(year, year1StartDate){
    const yearPick = {
        Year1 : 0,
        Year2 : 1,
        Year3 : 2,
        Year4 : 3,
        Year5 : 4
    }

    var yearVal = yearPick[year];
    // var year1StartDate = document.getElementById('year1StartDate').value;
    var dateObj = new Date(year1StartDate);
    var startingYear = dateObj.getUTCFullYear();
    var startingMonth = dateObj.getUTCMonth();
    var yearValue = startingYear + yearVal;
    return `${year} (${startingMonth<9?'0':''}${startingMonth+1}/${yearValue} - 12/${yearValue})`;
    
}

export function restructureData(data) {
    var restructured = {};
    for (var i = 0; i < data.length; i++) {
        var item = data[i];
        if (!restructured[item.Channel]) {
            restructured[item.Channel] = {};
        }
        restructured[item.Channel][item.Year] = item.Value;
    }
    return restructured;
};


export function formatCurrencyExp(value) {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(value);
}
export function formatDecimal(value) {
    if (value) {
        return new Intl.NumberFormat('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(value);
    }
}
export function formatDecimalToPercentage(value) {
    if (value) {
        return `${(value*100).toFixed(2)}%`;
    } else return '0.00%'
}
export function formatPerBrandEstimates(value) {
    if (isNaN(value)) {
        return "--";
    }
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2 }).format(value);
}


export function sumTotals(restructuredData, wac){
    // var dateObj = new Date(year1StartDate);
    // var startingYear = dateObj.getUTCFullYear()

    if (wac) {
        var wacs = ['WAC1', 'WAC2', 'WAC3', 'WAC4', 'WAC5'];
        // Initialize a totals array if needed
        var totals = Array(wacs.length).fill(0);

        var rowCount = 0;
        for (var channel in restructuredData) {
        
            for (var i = 0; i < wacs.length; i++) {
            
                var value = restructuredData[channel][wacs[i]];
            
                    totals[i] += value; // Add the value to the totals if it's one of the desired tables
            }
            rowCount++;
        }
    }
    else {
        var years = ['Year1', 'Year2', 'Year3', 'Year4', 'Year5'];
        // Initialize a totals array if needed
        var totals = Array(years.length).fill(0);

        var rowCount = 0;
        for (var channel in restructuredData) {
        
            for (var i = 0; i < years.length; i++) {
            
                var value = restructuredData[channel][years[i]];
            
                    totals[i] += value; // Add the value to the totals if it's one of the desired tables
            }
            rowCount++;
        }
    }

    return totals
};
