import React, { useContext } from 'react';
import { UseContextClues } from '../../context/ContextClues';
import { globalUpdateKeyValue } from '../../utils/globalHandlers';
import { handlePricingLensChange } from '../../utils/pricingLens';

export default function PricingLensControls({switchContext}) {
    
    const context = UseContextClues();

    return (
        <>
            <table>
                <thead>
                    <tr>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr className={context.pricingLens[switchContext]?.percentageByYear?"pointer-events-none text-gray-300":""}>
                        <td >Pass Back %:</td>
                        <td  className='whitespace-nowrap'>
                            <input 
                                name='passBack' 
                                min={0} 
                                max={100} 
                                step={.1} 
                                value={(context.pricingLens[switchContext]?.priceLens?.passBack*100).toFixed(1)} 
                                className={" border px-1"} 
                                type='number' 
                                onChange={(e) => handlePricingLensChange(null, null, (e.target.value/100), e.target.name, switchContext, context)} 
                            />%
                        </td>
                    </tr>
                    <tr className={context.pricingLens[switchContext]?.hedgeCheck?"pointer-events-none text-gray-300":""}>
                        <td>Blanket Hedge:</td>
                        <td>
                            <input 
                                name='blanket' 
                                min={-100} 
                                max={100} 
                                step={.1} 
                                value={(context.pricingLens[switchContext]?.priceLens?.blanket*100).toFixed(1)} 
                                className={`${!context.pricingLens[switchContext]?.hedgeCheck&&context.pricingLens[switchContext]?.priceLens?.blanket<0?"bg-primary-red text-white": ""} border px-1 ` } 
                                type='number' 
                                onChange={(e) => handlePricingLensChange(null, null, (e.target.value/100), e.target.name, switchContext, context)} 
                            />%
                        </td>
                    </tr>
                    <tr>
                        <td className='pr-8'>Hedge by Channel:</td>
                        <td>
                            <input 
                                type='checkbox' 
                                onChange={() => globalUpdateKeyValue("hedgeCheck", !context.pricingLens[switchContext]?.hedgeCheck, context.setPricingLens, switchContext) } 
                                defaultChecked
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className='pr-8'>% by Year:</td>
                        <td>
                            <input 
                                type='checkbox' 
                                onChange={() => globalUpdateKeyValue("percentageByYear", !context.pricingLens[switchContext]?.percentageByYear, context.setPricingLens, switchContext) } 
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </>
    );
};