import React from 'react';
import { formatCurrency } from '../../utils/analytics';
import LineBar from '../Charts/LineBar';

export default function AnalyticsCategoriesTable({tableStyles, viewGraph, headerStyles, useNewData }) {
    return (
        <>
            <table className={tableStyles}>
                <thead>
                    {viewGraph.categories&&<tr>
                        <td className={headerStyles}>
                            category
                        </td>
                        <td className={headerStyles+" text-right"}>
                            Year 1 Rebates
                        </td>
                        <td className={headerStyles+" text-right"}>
                            % of total
                        </td>
                    </tr>}
                </thead>
                <tbody>
                    {viewGraph.categories?useNewData.categories?.map((row, idx) => (
                        <tr key={`Category key: ${row.category}, index: ${idx}`}>
                            <td>
                                {row.category}
                            </td>
                            <td className='text-right'>
                                {formatCurrency(row.value)}
                            </td>
                            <td className='text-right'>
                                {(row.percentage).toFixed(1)}%
                            </td>
                        </tr>
                    ))
                    :
                    <tr>
                        <td className='flex justify-center'>
                            <LineBar data={useNewData.categories} teller={"category"} bar={'percentage'} crossLine={true}/>
                        </td>
                    </tr>
                }
                </tbody>
            </table>    
        </>
    )
};