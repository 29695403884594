import React, { useContext, useEffect, useRef, useState } from 'react';
import HandleOutsideClick from '../../hooks/handleOutsideClick';
import { produceDate } from '../../utils/globalHandlers';
import { previewClaimsDistribution } from '../../utils/claimsLoad';
import { UseToggleContext } from '../../context/ToggleContexts';


export default function SearchField({setName, set, current, style, placeholder, searchId, name, textBlack, specialObj, dateRange, setDateRange}){

    const boxRef = useRef(null);
    const focusRef = useRef(null);

    const toggler = UseToggleContext();

    const [ selected, setSelected ] = useState('')
    const [ focus, toggleFocus ] = useState(true);

    const [ delayedTimer, setDelayedTimer ] = useState(null)

    var focused = HandleOutsideClick(boxRef, setSelected, toggleFocus); 

    function handleMouseDown() {
        toggleFocus(true)
    };

    function handleKeydown(e) {
        if (e.key=="Escape") {
            toggleFocus(false)
        };
        if (e.key=="ArrowDown") {
            setSelected('')
            const item = focusRef.current;
            item.focus();
        };
    };

    function openDateRange(tableName, event) {
        previewClaimsDistribution(tableName, event, toggler.setDateRange) 
    };

    useEffect(() => {
        toggler.setDateRange&&toggler?.setDateRange(data => ({
           ...data,
           focus: false
        }));        
    }, [focus])

    function handleMouseDateRangeHover(event, tablename) {
        setDelayedTimer(setTimeout(() => {
            dateRange&&openDateRange(tablename, event)
        }, 2000))
    }

    function handleMOuseLeave() {
        toggler?.setDateRange({
            location: {
                pageX: 0,
                pageY: 0
            },
            data: [],
            dates: {
                min: null,
                max: null,
            },
            focus: false
        });
        clearTimeout(delayedTimer);
    };

    return (
        <div className='relative'  ref={boxRef}>
            <input  name={name}  className={style} type="text" id={searchId} placeholder={placeholder} onChange={e => (setName(e.target.value))} onKeyDown={handleKeydown} onMouseDown={handleMouseDown} value={current} autoComplete='off' required/>
            {focus&&!focused&&current?.length>1&&
                <ul ref={focusRef} className='absolute w-full bg-white z-20  max-h-[15vh] overflow-y-auto border border-t-0 rounded-b-lg shadow-xl'>
                    {specialObj?
                    specialObj?.filter(x => x !== '' && !selected.includes(x)).map((el, idx) => (
                        <li key={`Search query list table name: ${el?.[1]?.table_name}, index: ${idx}`} className={`relative p-2 hover:bg-black ${textBlack?'text-black':""} hover:text-white border cursor-pointer`} 
                            onClick={e => (setSelected([el?.[1]?.table_name]), setName(el?.[1]?.table_name), boxRef.current.blur(), toggleFocus(false))}
                            onMouseEnter={e => handleMouseDateRangeHover(e, el?.[1]?.table_name)}
                            onMouseLeave={handleMOuseLeave}
                            >
                                <div className='flex justify-between'>
                                    <span className='font-bold'>{el?.[1]?.table_name}</span>
                                    <span className=' font-medium'>{el?.[1]?.created_by}</span>
                                </div>
                                <div className='text-[9px] text-gray-400 font-light'>{produceDate(el?.[1]?.created_at)}</div>
                        </li>
                    )):
                    set?.filter(x => x !== '' && !selected.includes(x)).map((el, idx) => (
                        <li key={`Search query list item: ${el}, index: ${idx}`} 
                            className={`p-2 hover:bg-black ${textBlack?'text-black':""} hover:text-white cursor-pointer`} 
                            onClick={e => (setSelected([e.target.innerHTML]), setName(e.target.innerHTML))}
                        >
                            {el}
                        </li>
                    ))}   
                </ul>
            }
        </div>
    );
};