import React, { useState } from 'react';
import FormButton from "../Buttons/FormButton";
import { UseToggleContext } from '../../context/ToggleContexts';
import { UseUserContext } from '../../context/UserContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { globalBoolHandler, globalChangeHandler } from '../../utils/globalHandlers';
import { signup } from '../../utils/accounts';

export default function SignUpForm({height, addXMark}) {

    const account = UseUserContext();
    const toggler = UseToggleContext();

    const [payload, setPayload] = useState({
        username: "",
        email: "",
        first_name: "",
        last_name: "",
        user_type: "",
        password: "",
        confirm_password: "",
        orgid: "",
    });

    async function handleSignUp(event) {
        event.preventDefault();
        if (payload.password === payload.confirm_password) {
            setMessage("");
            let respoonse = await signup(payload, setMessage);

            if (respoonse) {
                alert('User added!');
                setPayload({
                    username: "",
                    email: "",
                    first_name: "",
                    last_name: "",
                    user_type: "",
                    password: "",
                    confirm_password: "",
                    orgid: "",
                });
            };
        } else {
            setMessage("Your password combination does not match. Please try again.");
        };
    };

    const [ message, setMessage ] = useState("");

    const labelStyles = "flex flex-col";
    const inputStyles = "px-2 py-2 border rounded mt-1 mb-1";

    return (
        <>
            <form className={`flex flex-col justify-center items-center overflow-y-auto ${height?height:"h-[100vh]"}`} onSubmit={handleSignUp}>
                <div className="flex relative bg-primary-light flex-col border p-16 rounded-xl shadow-lg shadow-gray-600">
                    {addXMark&&<button type='button' className={"absolute top-3 right-3 "} onClick={() => globalBoolHandler("auxLogin", toggler.toggle, toggler.setToggle, false)}>
                        <FontAwesomeIcon className={`text-sm transition-all duration-500 hover:rotate-[180deg] ${false ? "" : ""}`} icon={faXmark}/> 
                    </button>}
                    <div className="text-4xl font-medium pb-8">Create a New User</div>
                    <label htmlFor="username" className={labelStyles}>
                        Username<input name="username" className={inputStyles} value={payload.username} type="text" autoComplete="username" aria-labelledby="Enter your username" onChange={e => globalChangeHandler(e, payload, setPayload)} required/>
                    </label>
                    <label htmlFor="email" className={labelStyles}>
                        Email<input name="email" className={inputStyles} type="email" value={payload.email} autoComplete="email" aria-labelledby="Enter your email" onChange={e => globalChangeHandler(e, payload, setPayload)} required/>
                    </label>
                    <div className='flex gap-4'>
                        <label htmlFor="first_name" className={labelStyles}>
                            First Name<input name="first_name" className={inputStyles} type="text" value={payload.first_name} autoComplete="first_name" aria-labelledby="Enter your first name" onChange={e => globalChangeHandler(e, payload, setPayload)} required/>
                        </label>
                        <label htmlFor="last_name" className={labelStyles}>
                            Last Name<input name="last_name" className={inputStyles} type="text" value={payload.last_name} autoComplete="last_name" aria-labelledby="Enter your last name" onChange={e => globalChangeHandler(e, payload, setPayload)} required/>
                        </label>
                    </div>
                    <label className={labelStyles}>User Type
                        <select name="user_type" className={inputStyles} aria-labelledby="Enter user type" value={payload.user_type} onChange={e => globalChangeHandler(e, false, setPayload)} required>
                            <option value=''>-- select one --</option>
                            <option value='user'>user</option>
                            <option value="trade_user">trade user</option>
                            <option value="uw_user">underwriter</option>
                            <option value="client_mgr">client manager</option>
                            <option value="admin">admin</option>
                            <option value="superadmin">super admin</option>
                        </select>
                    </label>
                    <label htmlFor="orgid" className={labelStyles}>
                        Orgid<input name="orgid" className={inputStyles} type="text" value={payload.orgid} autoComplete="orgid" aria-labelledby="Enter your orgid" onChange={e => globalChangeHandler(e, payload, setPayload)} required/>
                    </label>
                    <label htmlFor="password" className={labelStyles}>
                        Password<input name="password" className={inputStyles} type="password" value={payload.password} autoComplete="password" aria-labelledby="Enter your password" onChange={e => globalChangeHandler(e, payload, setPayload)} required/>
                    </label>
                    <label htmlFor="confirm_password" className={labelStyles}>
                        Confirm Password<input name="confirm_password" className={inputStyles} type="password" value={payload.confirm_password} autoComplete="confirm_password" aria-labelledby="Enter your password" onChange={e => globalChangeHandler(e, payload, setPayload)} required/>
                    </label>
                    <div className={`${message.length>0?"my-2":''} text-primary-red max-w-[] text-xs`}>{message}</div>
                    <FormButton buttonText={"Create user"} />
                </div>
            </form>
        </>
    )
};