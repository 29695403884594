import React, { useContext } from "react";
import { Link } from "react-router-dom";
import PageSection from "../components/Section/PageSection";
import SEO from "../components/SEO/SEO";
import { UseToggleContext } from "../context/ToggleContexts";

export default function Splash() {

    const toggler = UseToggleContext();
    return (
        <>
            <SEO title="259Rx | Rebate Formulary Experts" description="Expert analysis and insights for your formulary rebate needs." />
            <PageSection >
                <div  className="block my-auto h-full bg-background-light">
                    <div className=" bg-primary-dark h-full text-center">
                        <div className="flex flex-col items-center justify-center px-20 h-full text-background-light mt-[-100px]" >
                            <h1 className='capitalize text-5xl md:text-6xl font-medium '>
                                formulary rebate expertise
                            </h1>
                            <div className='capitalize md:text-xl font-light my-8'>
                                Expert analysis and insights for your formulary rebate needs.
                            </div>
                            <div className="flex gap-2">
                                <Link className="hidden md:block px-6 py-4 font-bold rounded bg-light-blue  text-white hover:bg-hover-blue drop-shadow-xl" title="Log in to use software" to={'/login'}>Log in</Link>
                                <Link className=" px-6 py-4 font-bold rounded bg-light-blue  text-white hover:bg-hover-blue drop-shadow-xl" title="Learn more about 259Rx" to={'/about'}>Learn More</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </PageSection>
        </>
    );
};