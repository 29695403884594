import React, { useContext } from 'react';
import { UseContextClues } from '../../context/ContextClues';
import { UseToggleContext } from '../../context/ToggleContexts';
import { downloadToPDFGlobal } from '../../utils/download';
import { globalBoolHandler } from '../../utils/globalHandlers';
import { faCompress, faExpand, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Toggle from 'react-toggle'

export default function CrystalBallControls({tableRef, breakEvenGrid, breakEvenBool, setShift, setBreakEvenBool, loadingOptimize, shift}) {

    const context = UseContextClues();
    const toggler = UseToggleContext();

    // console.log('thisiswhatineed', tableRef.current.clientWidth)
    
    function handleDownload(e) {
        e.preventDefault();
        downloadToPDFGlobal(tableRef, "CrystalBall_Optimization_Tables", false, "landscape", null, tableRef.current.clientWidth, tableRef.current.clientHeight, toggler.setStatus);
    };
    
    function togglePanel(e){
        e.preventDefault();
        globalBoolHandler("breakEven", toggler.toggle, toggler.setToggle, !toggler.toggle.breakEven);
    };

    return (
        <div className='flex flex-row-reverse pl-6 pr-6'>
            {context.crystal&&<button aria-label='expand or collapse crystal ball window' className='float-right ' onClick={togglePanel}>
                {toggler.toggle?.breakEven?
                <FontAwesomeIcon className='text-lg text-primary-dark hover:text-primary-red ' icon={faCompress}/>
                :<FontAwesomeIcon className='text-lg text-primary-dark hover:text-primary-red ' icon={faExpand}/>}
            </button>}
            {toggler.toggle?.breakEven&&<div className='float-right px-8 border flex items-center py-2 mr-8 rounded bg-primary-light shadow'>
                {breakEvenGrid&&<label className='text-sm flex mr-6' htmlFor='downloadPDF'> 
                    <button aria-label='download to pdf' type='button' className='' onClick={handleDownload}>
                        <FontAwesomeIcon className='text-2xl text-primary-dark hover:text-slate-600 ' icon={faFilePdf}/>
                    </button>
                </label>}
                <label className={`text-xs flex items-center mr-6 gap-1 ${!breakEvenBool?"":"text-gray-300"}`} htmlFor='shift_pct'>
                    <span className={`mr-3`}>Expected Shift</span>
                    <input 
                        name='shift_pct' 
                        type='number'
                        disabled={breakEvenBool} 
                        className='border rounded border-primary-dark py-1 px-2 w-20 disabled:border-gray-300 disabled:cursor-not-allowed' 
                        onChange={e => setShift(e.target.value)}
                        min={0}
                        max={100}
                        step={0.01}
                        value={shift}
                    />%
                </label>
                <label className='text-xs flex items-center gap-6' htmlFor='breakeven'>
                    Breakeven
                    <Toggle
                        id='breakeven-toggle'
                        defaultChecked={breakEvenBool}
                        onChange={() => setBreakEvenBool(!breakEvenBool)} 
                    />
                </label>
                <button aria-label='show optimization results'  type='submit' className='flex items-center text-xs border px-8 py-2 ml-6 rounded text-white bg-light-blue hover:bg-hover-blue' >
                    Optimize
                    {loadingOptimize?
                        <span className='ml-4'>
                            <l-tail-chase
                                size={"15"}
                                speed="1.75" 
                                color="white" 
                            ></l-tail-chase>
                        </span>
                        :
                        null
                    }
                </button>
            </div>}
        </div>
    );
};