import React,{ useContext, useEffect } from 'react';
import { UseContextClues } from '../../context/ContextClues';
import { UseToggleContext } from '../../context/ToggleContexts';
import { UseUserContext } from '../../context/UserContext';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { checkUser } from '../../utils/accounts';
import NotFound404 from '../NotFound404/NotFound404';
import { loadSaved } from '../../utils/loadSave';
import CaseManager from '../../pages/CaseManager';
import UserLogs from '../../pages/UserLogs';
import { handleGlobalLogout } from '../../utils/globalHandlers';
import PrivacyPolicy from '../../pages/PrivacyPolicy';
import ContractualDefinition from '../ContractualDefinition/ContractualDefinition';
import { fetchAndLoadFormulary } from '../../utils/formularySelection';
import { listCustomList } from '../../utils/customLists';
import { loadModelSettings } from '../../utils/claimsLoad';
import Navbar from '../Navbar/Navbar';
import Login from '../../pages/Login';
import SignUp from '../../pages/SignUp';
import About from '../../pages/About';
import Splash from '../../pages/Splash';
import Contact from '../../pages/Contact';
import FullSection from '../Section/FullSection';

export default function AppSection({}) {

    const navigate = useNavigate();

    const account = UseUserContext();
    const context = UseContextClues();
    const toggler = UseToggleContext();

    useEffect(() => {
        async function fetchUser() {
            toggler.setLoading&&toggler?.setLoading(false);
            return await checkUser(account.setUser, context, toggler);
        };
        fetchUser().then(() => toggler.setLoading&&toggler?.setLoading(true))
    }, [])
    const local = sessionStorage.getItem("259rx_data");
    const stored = JSON.parse(local);

    useEffect(() => {
        if (context.casenumber) {
            sessionStorage.setItem("259rx_data", JSON.stringify({
                ...stored,
                casenumber: context.casenumber,
            }));
        };
        // setLoading(true);

        // may need a better way to load
        setTimeout(() => {
            if (stored?.casenumber&&!toggler?.isLoading) {
                loadSaved(stored.casenumber, context, toggler);
            };
        }, 0);
        
    }, []);

    useEffect(() => {
        if (toggler?.isLoading&&account.user.userData?.username) {
            navigate('/')
        };
    }, [account.user.userData?.username]);

    useEffect(() => {
        if (toggler.userActivity === 'Idle') {
            handleGlobalLogout(account, context, toggler);
            clearInterval(window.idleIntervalTimer);
    
        }
      }, [toggler.userActivity]);


    useEffect(() => {
        window.addEventListener('resize', () => toggler.setHeight(window.innerHeight))
    }, [window.onresize, toggler.pageHeight, window.innerHeight]);
    useEffect(() => {
        window.addEventListener('resize', () => toggler.setWidth(window.innerWidth))
    }, [window.onresize, toggler.pageWidth, window.innerWidth]);

    useEffect(() => {
          loadModelSettings(context.setModels);
      }, [context.casenumber, account.user?.userData.username])
      useEffect(() => {
          listCustomList(context.setModels);
      }, [context.casenumber, account.user?.userData.username])
    
        useEffect(() => {
          fetchAndLoadFormulary(context.addFormularies);
      }, [account.user?.loggedIn]);

    return (
        <>
            {
                <Routes>
                    <Route path='/' element={account.user.loggedIn ? <FullSection /> :  toggler?.isLoading&&<><Navbar /><Splash /></>  } /> 
                    <Route path='/login' element={ toggler?.isLoading&&account.user.loggedIn ? <Navigate to="/" /> :<Login />} />
                    <Route path='/signup' element={account.user.userData?.username&&toggler?.isLoading&&account.user.userData?.user_type==="superadmin"?<SignUp />:<Login />} />
                    <Route path='/user-logs' element={account.user.userData?.username&&toggler?.isLoading&&account.user.userData?.user_type==="superadmin"?<><Navbar /><UserLogs /></>:<Login />} />
                    <Route path='/case-manager' element={account.user.loggedIn&&account.user.userData?.user_type==="client_mgr" ?<><Navbar /><CaseManager /></>:<Login />} />
                    <Route path='/contractual-definition' element={account.user.loggedIn&&account.user.userData?.user_type==="client_mgr" ?<><Navbar /><ContractualDefinition /></>:<Login />} />
                    <Route path='/about' element={<><Navbar reverse={"bg-black"} /><About /></>} />
                    <Route path='/contact' element={<><Navbar /><Contact /></>} />
                    <Route path='/privacy' element={<><Navbar /><PrivacyPolicy /></>} />
                    {toggler?.isLoading&&<Route path="/*" element={<><NotFound404 /></>} />}
                </Routes>
            }
        </>
    );
};