import React, { useContext, useEffect, useMemo, useState } from 'react';
import {cloneCase, deleteSavedCase, downloadCase, getAggStatsForOrg, getCasesForOrganization} from '../utils/loadSave';
import { formatDecimal } from '../utils/resultsSummary';
import SaveCaseForm from '../components/Forms/SaveCaseForm';
import { useNavigate } from 'react-router-dom';
import SimpleButton from '../components/Buttons/SimpleButton';
import Select from 'react-select'
import { getOrgTags } from '../utils/accounts';
import { UseUserContext } from '../context/UserContext';
import { UseToggleContext } from '../context/ToggleContexts';
import { UseContextClues } from '../context/ContextClues';

export default function CaseManager() {

    const navigate = useNavigate();

    const account = UseUserContext();
    const context = UseContextClues();
    const toggler = UseToggleContext();

    const headerStyles = "first:text-left px-4 text-xs whitespace-nowrap py-2 capitalize border-b";
    const bodyStylles = "px-4 text-xs border-b border-t text-center ";
    const sideHeaders = "border px-3 py-1 font-medium text-xs";
    const summaryBodyStyles = "text-center font-light text-xs border";
    
    const [cases, setCases] = useState();
    const [aggStats, setAggStats] = useState();
    const [updated, updateMe] = useState(0);
    const [hashtags, setTags] = useState(
        {value: "", label: ""}
    );
    const [memoNote, setMemoNote] = useState({
        casename:"",
        content: {
            memo: ""
        }
    });

    const [ selected, setList ] = useState([]);

    useEffect(() => {
            async function retriveCases() {
                setCases(await getCasesForOrganization());
            };
            async function retriveStats() {
                setAggStats(await getAggStatsForOrg(selected));
            };
        setTimeout(() => {
            retriveStats().then(() => retriveCases());
        }, 500);
    }, [updated]);

    useEffect(() => {
        async function retriveStats() {
            setAggStats(await getAggStatsForOrg(selected));
        };
        retriveStats();
    }, [selected])

    useEffect(() => {
        if (toggler.isLoading&&!account.user.loggedIn) {
           navigate('/login');
        }
    }, [account.user.loggedIn])

    function handleCaseChange(casename, memo) {
        setMemoNote({
            casename,
            content: memo
        });
    };

    useEffect(() => {
        getOrgTags(setTags)
    }, [])

    async function handleDelete(casenumber, username) {
        if ((context.casenumber!==casenumber)) {
            const confirmed = window.confirm("Are you sure you want to delete Case No.: "+casenumber)
            if (confirmed) {
                const data =  await deleteSavedCase(casenumber, username)
                
                if (data.ok) {
                    setTimeout(() => {
                        updateMe(updated+1);
                        alert('Case '+casenumber+" deleted!!");
                    }, 500);
                }
                else alert('Error: Case No.: '+ casenumber+" not deleted.")
            };
        } else {
            alert("You cannot delete the current case you are on working!");
        };
    };

    async function handleClone(casenumber) {
        const confirmed = prompt("Please enter a new case name:", "")
        const assigneToMe = prompt("Assign this case to me? Type yes otherwise click cancel:", "")

        if (confirmed==="") {
            alert("You must enter a new case name in order to clone a case!")
        } else if (confirmed) {
            let data = await cloneCase(casenumber, confirmed, account.user.userData.username, assigneToMe);
            if (data.ok) {
                setTimeout(() => {
                    updateMe(updated+1);
                    // alert('Case '+casenumber+" cloned with new name " + confirmed + " and " + assigneToMe?"assigned":"not assigned" + " to you!!");
                    alert(`Case ${casenumber} cloned with new name ${confirmed} and ${assigneToMe?"assigned":"not assigned"} to you!!`);
                }, 500);
            };
        };
    };

    function tableRow(row) {
        return (
            <tr key={`Summary stat panel table ${row}`}>
                <td className={sideHeaders+" uppercase"}>
                    {row}
                </td>
                <td className={summaryBodyStyles}>
                    {formatDecimal(aggStats?.[row]?.total_cases)||0}
                </td>
                <td className={summaryBodyStyles}>
                    ${formatDecimal(aggStats?.[row]?.total_std_year1_rebates)||0}
                </td>
                <td className={summaryBodyStyles}>
                    ${formatDecimal(aggStats?.[row]?.total_guaranteed_year1)||0}
                </td>
            </tr>
        )
    }
    
    const caseHeaders = [
        "case name",
        "Data table",
        "user",
        "Year 1 Start Date",
        // "Claim count",
        // "Year 1 Rebate $ Earnings",
        // "Year 1 guaranteed $",
        "Deal status",
        "Change Deal Status",
        "Clone Deal",
        "Download Detail",
        "Delete Case",
    ];

    function handleTagChange(e) {

        // context.setSettings({
        //     ...context.settings,
        //     locked: true
        // })
  
        let list = []
        if (e.length>0) {
            e.forEach(ele => {
                ele.label!=="All"&&list.push(`${ele.value}`)
            });
            setList(list);
        } else {
            setList([])
        }
    };

    function checkStringArray(arr, target) {
        return arr.every((item) => target.includes(item))
    };

    return (
        <div className='bg-background-light'>
            <div  className='relative max-w-[1290px] bg-white mx-auto px-4 overflow-x-auto '>

                                                  
                {/* client controls */}

                <div className='flex justify-between items-center pt-3 pb-2'>
                    <div className='uppercase font-bold mb-2 text-base'>
                        Case Management
                    </div>
                    <div className='flex items-center text-sm'>
                        Filter by tags:
                        <Select 
                            isMulti
                            options={hashtags}
                            // options={context.carriers.map((value, idx) => ({
                            //     value: {
                            //         ...value,
                            //         toString: () => `${value.value}`,
                            //     },
                            //     label: `${idx+1}: ${value.value}`,
                            // }))}
                            onChange={handleTagChange}
                            // value={defaultCarriers}
                            // getOptionValue={value => value}
                            name='carrier'
                            className='pl-2 w-[300px]'
                        />
                    </div>

                </div>
  

                <div style={{ height: (toggler.pageHeight*0.55) }} className='overflow-y-auto border rounded-t-lg'>

                    {/* table for cazse manfger */}

                    <table  className='overflow-y-auto w-full'>
                        <thead className=''>
                            <tr className='sticky top-0 bg-white'>
                                {caseHeaders.map((caseHead, idx) => (
                                    <th key={`${idx}: Header titles - ${caseHead}`} className={headerStyles}>
                                        {caseHead}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody >
                            {selected.length>0?cases?.map((bycase, idx) => (
                                    checkStringArray(selected, bycase.notes)&&<tr className={`cursor-pointer hover:bg-light-blue ${idx%2===0&&"bg-background-light"} ${memoNote.casename===bycase.case&&"bg-light-blue text-white"}`} 
                                        key={`Selected case manager table ${bycase.case} - ${idx}`} 
                                        onClick={() => handleCaseChange(bycase.case, bycase)}
                                        >
                                        <td className={"px-4 text-xs border-b border-t text-left"}>
                                            {bycase.case}
                                        </td>
                                        <td className={bodyStylles}>
                                            {bycase.tName}
                                        </td>
                                        <td className={bodyStylles}>
                                            {bycase.user}
                                        </td>
                                        <td className={bodyStylles}>
                                            {bycase.year1}
                                        </td>
                                        <td className={bodyStylles+' uppercase'}>
                                            {bycase.status}
                                        </td>
                                        <td className={"text-xs border-y"}>
                                            <SaveCaseForm selectedCase={bycase.case} current={bycase.status} updateMe={updateMe} updated={updated} optionalColor={memoNote.casename===bycase.case} />
                                        </td>
                                        <td className='text-center border py-2'>
                                            <button onClick={e => handleClone(bycase.case)} className='text-xs uppercase border bg-light-blue hover:bg-hover-blue px-5 py-2 text-white font-bold rounded hover:shadow-lg'> 
                                                clone
                                            </button>
                                        </td>
                                        <td className='text-center border py-2'>
                                            <SimpleButton download={true} tableName={bycase.user} setModels={bycase.case} />
                                        </td>
                                        <td className='text-center border py-2'>
                                            <button type='button' onClick={() => handleDelete(bycase.case, bycase.user)} className='text-xs uppercase border bg-primary-red hover:bg-red-800 px-5 py-2 text-white font-bold rounded hover:shadow-lg'> 
                                                X
                                            </button>
                                        </td>
                                    </tr>
                                )):
                                cases?.map((bycase, idx) => (
                                    <tr className={`cursor-pointer hover:bg-light-blue ${idx%2===0&&"bg-background-light"} ${memoNote.casename===bycase.case&&"bg-light-blue text-white"}`} 
                                        key={`Non-selected case manager table ${bycase.case} - ${idx}`} 
                                        onClick={() => handleCaseChange(bycase.case, bycase)}
                                        >
                                        <td className={"px-4 text-xs border-b border-t text-left"}>
                                            {bycase.case}
                                        </td>
                                        <td className={bodyStylles}>
                                            {bycase.tName}
                                        </td>
                                        <td className={bodyStylles}>
                                            {bycase.user}
                                        </td>
                                        <td className={bodyStylles}>
                                            {bycase.year1}
                                        </td>
                                        <td className={bodyStylles+' uppercase'}>
                                            {bycase.status}
                                        </td>
                                        <td className={"text-xs border-y"}>
                                            <SaveCaseForm selectedCase={bycase.case} current={bycase.status} updateMe={updateMe} updated={updated} optionalColor={memoNote.casename===bycase.case} />
                                        </td>
                                        <td className='text-center border py-2'>
                                            <button onClick={e => handleClone(bycase.case)} className='text-xs uppercase border bg-light-blue hover:bg-hover-blue px-5 py-2 text-white font-bold rounded hover:shadow-lg'> 
                                                clone
                                            </button>
                                        </td>
                                        <td className='text-center border py-2'>
                                            <SimpleButton download={true} tableName={bycase.user} setModels={bycase.case} />
                                        </td>
                                        <td className='text-center border py-2'>
                                            <button type='button' onClick={() => handleDelete(bycase.case, bycase.user)} className='text-xs uppercase border bg-primary-red hover:bg-red-800 px-5 py-2 text-white font-bold rounded hover:shadow-lg'> 
                                                X
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            
                            }
                        </tbody>
                    </table>
                </div>



                {/* // this is the bottom section */}
                <div  style={{ height: (toggler.pageHeight*0.45)-155 }} className='flex border rounded-b-lg p-4 overflow-y-auto bg-background-light'>
                    <div className='absolute left-0 right-0 flex justify-between px-8'>
                        <div><span className='font-bold pr-2 uppercase'>Claim count:</span>
                            {memoNote?.content.claims?formatDecimal(memoNote?.content.claims):"N/A"}
                        </div>
                        <div><span className='font-bold pr-2 uppercase'>Year 1 Rebate $ Earnings:</span>
                            {memoNote?.content.std_year1_rebates?formatDecimal(memoNote?.content.std_year1_rebates):"N/A"}
                        </div>
                        <div><span className='font-bold pr-2 uppercase'>Year 1 guaranteed $:</span>
                            {memoNote?.content.guaranteed_year1?formatDecimal(memoNote?.content.guaranteed_year1):"N/A"}
                        </div>
                    </div>
                    <div className='mt-10'>
                        <div className='uppercase font-bold mb-2 text-base'>
                            Summary Statistics Panel
                        </div>
                        <table className='border bg-white'>
                            <thead>
                                <tr className='border '>
                                    <th></th>
                                    <th className={headerStyles}># of cases</th>
                                    <th className={headerStyles}>Year 1 Earnings</th>
                                    <th className={headerStyles}>Year 1 Guarantees</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tableRow("draft")}
                                {tableRow("offered")}
                                {tableRow("accepted")}
                                {tableRow("executed")}
                                {tableRow("amended")}
                            </tbody>
                        </table>
                    </div>
                    
                    <div className='p-20'>
                        {memoNote?.content.notes}
                    </div>
        
                    {/* <div className='pl-6 w-full  pb-6 mt-10'>
                        <div className='text-lg w-full uppercase font-bold pb-1'>
                            memo:
                        </div>
                        <textarea className='border rounded w-full h-40 p-2 text-xs' value={memoNote?.content.notes}>

                        </textarea>
                     
                    </div> */}
                </div>
            </div>
        </div>
    )
};