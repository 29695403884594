import React, { useContext, useRef, useState } from 'react' 
import { UseToggleContext } from '../context/ToggleContexts';

export default function PrivacyPolicy() {

    const scrollRef = useRef({
        introOne: null,
        aboutTwo: null,
        useThree: null,
        infoFour: null,
        howFive: null,
        securitySix: null,
        ourSeven: null,
        howEight: null,
        yourNine: null,
        outsideTen: null,
    });
    const [ pageScroll, setScroll ] = useState();
    
    const toggler = UseToggleContext();

    const headingStyle = "font-bold text-2xl md:text-4xl py-8"; 
    const secondHeading = "font-bold md:text-2xl first:pt-0 py-6";
    const thirdHeading = "font-bold text-xl first:pt-0 py-6";
    const paragraph = " py-4";
    const listStyle = "ml-8";
    const secondIndent = "ml-5";
    const sectionIndent = "ml-14 py-4";
    const bulletStyle = "py-4" ;
    const sideButtonCLass = " my-3 whitespace-break-spaces w-1/2 text-left text-lg border-b  hover:border-b-primary-blue border-b-4 pb-2 font-medium ";

    return (
        <>
            <div className='bg-primary-dark  h-[0px] md:h-[170px] mx-auto'>
                <h1 className='text-white text-4xl md:text-6xl font-medium pt-10 text-center' >
                    Privacy Policy
                    <div className='text-xs font-light pt-10'>April 20, 2024</div>
                </h1>
                
            </div>
            <svg  width="" height="80" viewBox="0 0 500 80" preserveAspectRatio="none">
                <path d="M0,0 L0,40 Q250,80 500,40 L500,0 Z" fill="#00141c" />
            </svg>
            <div onScroll={e => setScroll(Math.floor(e.target.scrollTop))} style={{height: toggler.pageWidth<1120?toggler.pageHeight-160:toggler.pageHeight-330}} className='relative mt-[-20px]  mx-auto z-[10]  bg-white overflow-y-auto' >
                <div className='max-w-[1240px] mx-auto' >
                    
                    {/* controls */}                
                    <div style={{height: toggler.pageHeight-330}} className='fixed hidden md:block overflow-y-auto scrollbar-hide'>
                        <div className='flex flex-col mt-8 pl-12'>
                            <button className={`${sideButtonCLass} ${pageScroll<=574||!pageScroll?"border-b-primary-blue":"border-b-white"}`} onClick={() => scrollRef.current.introOne.scrollIntoView({behavior: "smooth"})}>Introduction</button>
                            <button className={`${sideButtonCLass} ${pageScroll>=575&&pageScroll<=1735?"border-b-primary-blue":"border-b-white"}`} onClick={() => scrollRef.current.aboutTwo.scrollIntoView({behavior: "smooth"})}>About us and this Policy</button>
                            <button className={`${sideButtonCLass} ${pageScroll>=1736&&pageScroll<=2063?"border-b-primary-blue":"border-b-white"}`} onClick={() => scrollRef.current.useThree.scrollIntoView({behavior: "smooth"})}>Use by minors</button>
                            <button className={`${sideButtonCLass} ${pageScroll>=2064&&pageScroll<=3455?"border-b-primary-blue":"border-b-white"}`} onClick={() => scrollRef.current.infoFour.scrollIntoView({behavior: "smooth"})}>Information we collect</button>
                            <button className={`${sideButtonCLass} ${pageScroll>=3456&&pageScroll<=5015?"border-b-primary-blue":"border-b-white"}`} onClick={() => scrollRef.current.howFive.scrollIntoView({behavior: "smooth"})}>How we use information</button>
                            <button className={`${sideButtonCLass} ${pageScroll>=5016&&pageScroll<=5295?"border-b-primary-blue":"border-b-white"}`} onClick={() => scrollRef.current.securitySix.scrollIntoView({behavior: "smooth"})}>Security</button>
                            <button className={`${sideButtonCLass} ${pageScroll>=5296&&pageScroll<=6944?"border-b-primary-blue":"border-b-white"}`} onClick={() => scrollRef.current.ourSeven.scrollIntoView({behavior: "smooth"})}>Our disclosures of information to others</button>
                            <button className={`${sideButtonCLass} ${pageScroll>=6945&&pageScroll<=7384?"border-b-primary-blue":"border-b-white"}`} onClick={() => scrollRef.current.howEight.scrollIntoView({behavior: "smooth"})}>How long we retain your information</button>
                            <button className={`${sideButtonCLass} ${pageScroll>=7385&&pageScroll<=12952?"border-b-primary-blue":"border-b-white"}`} onClick={() => scrollRef.current.yourNine.scrollIntoView({behavior: "smooth"})}>Your rights</button>
                            <button className={`${sideButtonCLass} ${pageScroll>=12952?"border-b-primary-blue":"border-b-white"}`} onClick={() => scrollRef.current.outsideTen.scrollIntoView({behavior: "smooth"})}>Outside Materials</button>
                        </div>
                    </div>

                    {/* privacy policy note */}
                    <div className='px-6 md:px-40 pt-4 pb-20 md:ml-[150px]'>
                        <h3 id='introOne' ref={ref => scrollRef.current.introOne = ref } className={headingStyle}>1.       Introduction</h3>
                        <p className={paragraph}>259Rx is committed to explaining how we gather and use your information safe and secure and handling it in accordance with our legal obligations.  This Privacy Policy (“Policy”) is designed to help you understand how we handle your data.  It also explains your rights and choices. 
                        This Policy applies when you use these services (which we call the “Platform” in this Policy):</p>
                        <ul className={listStyle}>
                            <li>·    259rx.com;</li>
                            <li>·    the Nestor Rebate Engine (“Nestor”);</li>
                            <li>·    anywhere else we gather information about you and refer to this Policy.</li>
                        </ul>
                        <p className={paragraph}>How we handle your information depends on which Platform services you use, and how you use them.  This Policy is grouped into these sections:</p>
                        <ul className={listStyle}>
                            <li>·    about us and this Policy;</li>
                            <li>·    information we collect;</li>
                            <li>·    how we use information, including for advertising purposes;</li>
                            <li>·    when we disclose information to other parties, including for advertising purposes; and</li>
                            <li>·    your rights and controls over information about you. </li>
                        </ul>
                        <p className={paragraph}>We encourage you to read this Policy carefully.  If you have questions, please contact us.</p>
                        
                        <h3 id='aboutTwo' ref={ref => scrollRef.current.aboutTwo = ref } className={headingStyle}>2.       About us and this Policy</h3>
                        
                        <ul className={''}>
                            <li className={secondHeading}>(a)     Who we are</li>
                            259Rx, LLC. operates the Platform and is referred to in this Policy as “259”, “we”, “our” or “us”.  This Policy supplements and is governed by our Terms of Service (“Terms”).  Capitalized terms that are used but not defined in this Policy are defined in our Terms.  The Terms describe how the Platform works in general and establishes a contract between you and us governing your use of the Platform.
                            <li className={secondHeading}>(b)     How to Contact Us</li>
                            If you have any questions or feedback about this Policy, you can contact us by visiting this page on our website: https://259rx.com/#/contact/
                            <li className={secondHeading}>(c)     When this Policy applies</li>
                            This Policy applies to you when you use the Platform, effective as of the Last Updated date above.  By using or accessing the Platform, you signify that you have read, understand and agree to be bound by this Policy and the Terms.  As explained below, Outside Materials are not part of the Platform, and this Policy doesn’t apply to any information you may exchange with providers of Outside Materials or other third parties.
                            Because the Platform changes often, this Policy may change over time.  Anytime we modify the Policy, we will post a revised version on the Platform and update the Last Updated date above.  If we make material changes and you have given us your contact information, we will notify you before the material changes take effect, so you have time to review them. 
                            <li className={secondHeading}>(d)     Location-specific sections</li>
                            <p className={paragraph}>The Platform operates from the United States, but this Policy applies worldwide.  Our practices generally do not differ based on your location, but your rights and choices depend in part on the law where you live.  For example, you may have rights under: (1) “GDPR”: implementations of the Data Protection Act 2018 and the General Data Protection Regulation (EU) 2016/679; or (2) “CCPA”: the California Consumer Privacy Act, as amended. 
                            As a result, certain sections of this Policy apply to you only if you reside in a particular location: </p>
                            <ul className={listStyle}>
                                <li>·    California residents should consult the Rights under California law section.  If you reside in a U.S. jurisdiction that has enacted a data privacy law similar to CCPA or GDPR, we extend the same rights CCPA grants to California residents to you, except where we specify otherwise. </li>
                                <li>·    Residents of jurisdictions where GDPR applies – such as U.K., EU and Swiss residents – should consult the Rights under GDPR and International Data Transfers sections. </li>
                            </ul>
                            <p className={paragraph}>If those sections apply to you, those sections override any contrary descriptions elsewhere in the Policy as they relate to you.  If you have questions about your rights under other data privacy laws, please contact us. </p>
                        </ul>   

                        <h3 id='useThree' ref={ref => scrollRef.current.useThree = ref } className={headingStyle}>3.       Use by minors</h3>
                        <p className={paragraph}>259 is strictly for adult users.  We do not knowingly collect information from anyone under the age of 18, and we do not share or sell information about anyone under 18 without affirmative authorization.  If we learn that we have collected information from a child under age 18, we will delete that information as quickly as possible. </p>
                        <ul className={listStyle}>
                            <li>·    If you are under 18:  sorry, but please leave the Platform.  If you’ve already sent us information, please contact us first so we can delete it. </li>
                            <li>·    If you are a parent or guardian of a child under 18 years of age and you believe your child has provided us with information, please contact us so that we can confirm that we have deleted it.</li>

                        </ul>

                        <h3 id='infoFour' ref={ref => scrollRef.current.infoFour = ref } className={headingStyle}>4.       Information we collect</h3>
                        
                        <h3 className={secondHeading}>(a)     Information you provide</h3>
                        <p className={paragraph}>We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:</p>
                        <ul className={listStyle}>
                            Email address<br />
                            First name and last name<br />
                            Phone number<br />
                            Address, State, Province, ZIP/Postal code, City<br />
                            Usage Data<br />
                        </ul>
                        <p className={paragraph}>When you submit information through the Platform, whether provided to create an Account or other activity, you are consenting to its collection, use and disclosure in accordance with this Policy.  Information you provide us may include personal identifiers.</p>
                        <h3 className={secondHeading}>(b)     Information collected when you use the Platform</h3>
                        <p className={paragraph}>As you use the Platform, cookies and other technology we use will generate technical data about which features you use, how you use them and the devices you use to access our services.  This information may include:</p>
                        <ul className={listStyle}>
                            <li>·    <b>“Device Information”</b> related to the device you use to interact with the Platform, such as your device’s IP address, advertising IDs (resettable, random numbers, such as the device’s Apple IDFA or Android Advertising ID), its browser and operating system, its internet service provider, and its configuration..</li>
                            <li>·    <b>“Internet Activity”</b> related to your use of the Platform, such as the pages you visit, the sites you use before or after visiting ours, your actions within the Platform, the content or advertisements you interact with, general geolocation information, time stamps and performance logs and reports.</li>
                        </ul>
                        <br/>
                        <u>Information generated when you use the Platform</u>
                        <br/>
                        <br/>
                        <u>Cookies and other technology</u>
                        <p className={paragraph}>We and third party partners collect personal information on the Platform using cookies, pixel tags or similar technologies. Our third party partners, such as web hosts, analytics and advertising partners, may use these technologies to collect information about your online activities over time and across different services.  We may use both session cookies (which are deleted from your device when you exit the Platform) and persistent cookies (which remain on your device for longer or until you delete them manually).  A session cookie disappears after you close your browser.  A persistent cookie remains after you close your browser and may be used by your browser on subsequent visits to our Platform.</p>
                        <u>Information we don’t collect</u>
                        <p className={paragraph}>For clarity, we do not collect:</p>
                        <ul className={listStyle}>
                            <li>·    Sensitive personal information</li>
                            <li>·    Personal information about anyone under the age of 18 (see Use by minors for more details)</li>
                            <li>·    Sensitive business information, as set forth in other agreements with user</li>
                        </ul>
                        <p className={paragraph}> We also don’t use, sell, or share any of those types of information. </p>
                        
                        <h3 id='howFive' ref={ref => scrollRef.current.howFive = ref } className={headingStyle}>5.       How we use information</h3>
                        <h3 className={secondHeading}> (a)     In general</h3>
                        <p className={paragraph}>Here is a detailed explanation of the various reasons we use your information, together with practical examples:</p>
                        <ul className={listStyle}>
                            <li><b>·    To provide our content, services and products to you</b></li>
                            <li className={secondIndent}>»   Create and manage your Account</li>
                            <li className={secondIndent}>»   Provide you with customer support and respond to your requests</li>
                            <li className={secondIndent}>»   Communicate with you about our services</li>
                            <li><b>·    To manage your Account </b></li>
                            <li className={secondIndent}>»   Register you on the services to allow you to utilize the Platform</li>
                            <li className={secondIndent}>»   Administer your account and new features  </li>
                            <li><b>·    To develop and improve our Platform</b></li>
                            <li className={secondIndent}>»   Administer focus groups, market studies and surveys</li>
                            <li className={secondIndent}>»   Review interactions with customer teams to improve our quality of service</li>
                            <li className={secondIndent}>»   Develop new content and services</li>
                            <li><b>·    To prevent, detect and fight fraud and other illegal or unauthorized activities</b></li>
                            <li className={secondIndent}>»   Find and address ongoing, suspected or alleged violations of our Terms</li>
                            <li className={secondIndent}>»   Better understand and design countermeasures against violations of our Terms</li>
                            <li className={secondIndent}>»   Retain data related to violations of our Terms to prevent against recurrences</li>
                            <li className={secondIndent}>»   Enforce or exercise our rights; for example, those in our Terms</li>
                            <li><b>·    To ensure legal compliance</b></li>
                            <li className={secondIndent}>»   Verify copyright or IP claims</li>
                            <li className={secondIndent}>»   Assist law enforcement</li>
                            <li className={secondIndent}>»   Comply with legal requirements, including in connection with any change of ownership or control of our business</li>
                        </ul>
                        <h3 className={secondHeading}> (b)     Purposes</h3>
                        <p className={paragraph}>We rely on the following purposes to collect and use your information as described in this Policy:</p>
                        <ul className={listStyle}>
                            <li className={bulletStyle}>·    <u>Commercial purposes:</u> At times, the reason we process your information to advance your economic interests or our economic interests.  These purposes include performing the contract that you have with us, as embodied by our Terms, which advance our economic interests and yours.  </li>
                            <li className={bulletStyle}>·    <u>Business purposes:</u> Most often, we process your information for operational reasons, in a reasonably necessary and proportionate manner (i.e., for business purposes under CCPA).  For instance, we analyze users’ behavior on our services to continuously improve our offerings, we suggest offers we think might interest you and promote our own services, we process information to help keep our members safe and we process data where necessary to enforce our rights, assist law enforcement and enable us to defend ourselves in the event of a legal action.</li>
                            <li className={bulletStyle}>·    <u>Comply with applicable laws and regulations:</u> We also process your information for purpose 6 above where it is necessary for us to comply with applicable laws and regulations and evidence our compliance with applicable laws and regulations.  For example, we retain traffic data and data about transactions in line with our accounting, tax and other statutory data retention obligations and to be able to respond to valid access requests from law enforcement. </li>
                            <li className={bulletStyle}>·    <u>Consent:</u> From time to time, we may ask for your consent to collect specific information, such as your precise geolocation, or use your information for specific reasons, like messaging your email address or phone number for direct marketing purposes.  In general, you may withdraw your consent by changing your settings (such as browser or device settings) or following instructions provided with information we send you on a consent basis (such as clicking ‘unsubscribe’ in any email or replying ‘STOP’ to any text we send you).  You may always withdraw your consent at any time – just contact us.</li>
                        </ul>
                        <h3 id='securitySix' ref={ref => scrollRef.current.securitySix = ref } className={headingStyle}>6.       Security</h3>
                        <p className={paragraph}> 259Rx has implemented technical, administrative and physical security measures to protect your information from unauthorized access, use or disclosure. Still, no data transmission over the Internet is 100% secure, so we cannot guarantee or warrant the security of any information you provide, and you do so at your own risk. We cannot promise that your information will remain absolutely secure in all circumstances. We are not responsible for the circumvention of any privacy settings or security measures we may provide.</p>
                        <h3 id='ourSeven' ref={ref => scrollRef.current.ourSeven = ref } className={headingStyle}>7.       Our disclosures of information to others</h3>
                        <p className={paragraph}>Since our goal is to help you discover great content and services, the principal reason we exchange your information is to enhance your experience of the Platform and make sure it works well for all users. 
                        This section describes how and why we exchange personal information with contractors and third parties.  It also describes exchanges made for certain purposes, such as for legal reasons and other uses you may consent to.  We also disclose deidentified and/or anonymized data for these purposes.</p>
                        <h3 className={secondHeading}> (a)     We do not ‘sell’ personal information</h3>
                        <p className={paragraph}> That means we don’t provide or exchange your personal information with others to faciliate behavioral ads (i.e., ads targeted to you) or otherwise sell it. </p>
                        <h3 className={secondHeading}> (b)     Functional disclosures</h3>
                        <p className={paragraph}>In addition to the use of trackers described above, we contract with companies or individuals to provide certain services related to the functionality and features of the Platform, including payment processing, email and hosting services, software development, shipping and fulfillment, data management, and administration of contests and other promotions.  We refer to them as “contractors.” 
                        We may disclose information about you, such as Personal Identifiers, Commercial Information, Internet Activity and Device Information, to contractors as necessary for them to perform their services.  Contractors are not permitted to use information about you for any other purpose.  In the past twelve (12) months, we have disclosed these types of information to the following types of contractors:</p>
                        <ul className={listStyle}>
                            <li className={bulletStyle}>·    Analytics providers to tell us how the Platform is doing, such as which parts interest visitors and how long they visit before leaving.  Among other data, they may receive your IP address.</li>
                            <li className={bulletStyle}>·    Various hosting services and data processors to provide the infrastructure of the Platform, such as [Cloudflare], which ensures that traffic is from real people, not computers.  Among other data, they may receive your IP address.</li>
                        </ul>
                        <h3 className={secondHeading}> (c)     For legal reasons</h3>
                        <p className={paragraph}>Finally, we may disclose personal information:</p>
                        <ul className={listStyle}>
                            <li className={bulletStyle}>·    In response to subpoenas, court orders, or other legal process; to establish or exercise our legal rights; to defend against legal claims; or as otherwise required by law.  In such cases we reserve the right to raise or waive any legal objection or right available to us;</li>
                            <li className={bulletStyle}>·    When we believe it is appropriate to investigate, prevent, or take action regarding illegal or suspected illegal activities; to protect and defend the rights, property, or safety of our company, our users, or others; and in connection with the enforcement of our Terms and other agreements; or</li>
                            <li className={bulletStyle}>·    In connection with a corporate transaction, such as a divestiture, merger, consolidation, or asset sale, or in the unlikely event of bankruptcy. </li>
                        </ul>
                        <h3 className={secondHeading}> (d)     With your consent or at your request</h3>
                        <p className={paragraph}>We may periodically ask for your consent to disclose your information to third parties.  Whenever we ask your consent for this reason, we will summarize the purpose and scope of the disclosure.  For example, we may offer discounts to you if you consent to join our mailing list or participate in a promotion involving direct marketing communications. </p>
                        <h3 id='howEight' ref={ref => scrollRef.current.howEight = ref } className={headingStyle}>8.       How long we retain your information</h3>
                        <p className={paragraph}>We retain your information only as long as we need it for the purposes described under How we use information, except when longer retention is required by our compliance policies and efforts toward applicable legal, tax, accounting and regulatory requirements. </p>
                        <p className={paragraph}>How long we need information for those purposes varies by category, and even within categories.  These retention determinations always consider the amount, nature, and sensitivity of the personal information, the potential risk of harm from its unauthorized use or disclosure, whether we can achieve those purposes without using the personal information.</p>
                        <p className={paragraph}>For example, we delete some Internet Activity at some soon as you exit the Platform, whereas we may retain records of your Quotes for services and products for several years as required by law or contract, such as agreements with our payment processors or under our accounting standards.</p>
                        <h3 id='yourNine' ref={ref => scrollRef.current.yourNine = ref } className={headingStyle}>9.       Your rights</h3>
                        <h3 className={secondHeading}> (a)     In General</h3>
                        <p className={paragraph}>We want you to be in control of your information, so we want to remind you of the following options and tools available to you:</p>
                        <ul className={listStyle}>
                            <li className={bulletStyle}>·    <u>Content opt-outs:</u>  you may “opt-out” of any newsletters or promotional communications from us by following the unsubscribe instructions in the communication you receive. You may also opt-out of receiving newsletters and/or promotional communications by contacting us.  We may continue to send you communications regarding the Platform, such as notices about administrative updates, transaction reports, and changes to the Platform, this Policy or the Terms.]</li>
                            <li className={bulletStyle}>·    <u>Exercising rights:</u>  If any of the location-specific rights listed below apply to you, see Requesting information.</li>
                        </ul>
                        <h3 className={secondHeading}> (b)     Rights under GDPR</h3>
                        <em>This section applies to you only if you reside in a jurisdiction where GDPR applies. </em>
                        <p className={paragraph}>For GDPR purposes, the data controller is 259Rx, LLC, 300 Tice Blvd, Suite 275, Woodcliff Lake NJ 07677. </p>
                        
                        <div className={sectionIndent}>
                            <h3 className={thirdHeading}> (i)      Lawful bases</h3>
                            <p className={paragraph}>If we are aware that you reside in a GDPR jurisdiction, we only collect, use or share information about you when we have a valid reason. This is called a “lawful basis.” Our lawful bases generally map to the Purposes above, but specifically include:</p>
                            <ul className={listStyle}>
                                <li>·         The consent you provide to us at the point of collection of your information</li>
                                <li>·         The performance of the contract we have with you</li>
                                <li>·         The compliance of a legal obligation to which we are subject, or</li>
                                <li>·         The legitimate interests of 259Rx or a third party.</li>
                            </ul>
                            <p className={paragraph}>We have a legitimate interest in gathering and processing personal data, for example: (1) to ensure that our networks and information are secure; (2) to administer and generally conduct our business; (3) to prevent fraud; and (4) to conduct our marketing activities.</p>
                            <h3 className={thirdHeading}> (ii)     GDPR rights</h3>
                            <p className={paragraph}>Depending on your jurisdiction’s enactment of GDPR, you may have these rights:</p>
                            <ul className={listStyle}>
                                <li>·         <b>Request access</b> (commonly known as a “data subject access request”). This enables you to receive a copy of the personal data we hold about you.</li>
                                <li>·         <b>Request correction</b>. This enables you to have any incomplete or inaccurate data we hold about you corrected, though we may need to verify the accuracy of the new data you provide to us.</li>
                                <li>·         <b>Request erasure</b>. This enables you to ask us to delete or remove personal data where there is no good reason for us to continue to process it. You also have the right to ask us to delete or remove your personal data where you have successfully exercised objected to processing (see below), where we may have processed your personal data unlawfully, or where we are required to erase your personal data to comply with local law.  We may not always be able to comply with your request of erasure for specific legal reasons.  If so, we will notify you at the time of your request.</li>
                                <li>·         <b>Object to processing</b> where we are relying on a legitimate interest (or those of a third party) and you object to particular processing due to a perceived impact on your fundamental rights and freedoms. You also have the right to object where we are processing your personal data for direct marketing purposes. In some cases, we may demonstrate that we have compelling legitimate grounds to process your information that override your rights and freedoms.</li>
                                <li>·         <b>Request restriction of processing</b> during the following scenarios: (a) if you want us to establish the data’s accuracy; (b) where our use of the data is unlawful, but you do not want us to erase it; (c) where you need us to hold the data even if we no longer require it as you need it to establish, exercise, or defend legal claims; or (d) you have objected to our use of your data, but we need to verify whether we have overriding legitimate grounds to use it.</li>
                                <li>·         <b>Request the transfer</b> of your personal data to you or a third party. We will provide to you, or a third party you have chosen, your personal data in a structured, commonly used, machine-readable format.  This right only applies to automated information that you initially provided consent for us to use or where we used the information to perform a contract with you.</li>
                                <li>·         <b>Withdraw consent at any time</b> where we are relying on consent to process your personal data. If you withdraw your consent, we might not be able to provide certain products or services to you. We will advise you if this is the case at the time you withdraw your consent. For clarity, withdrawal is not retroactive.</li>
                            </ul>
                            <p className={paragraph}>We do not charge for access to your personal data or to exercise any of the other rights.  However, we may refuse to comply with your request or charge a reasonable fee if your request is clearly unfounded, repetitive, or excessive. </p>
                        </div>
                        <h3 className={secondHeading}> (c)     International Data Transfers</h3>
                        <p className={paragraph}>If you reside outside the United States, we transfer information about you for processing in the United States.  By providing your information to us, you consent to the processing of the information in the United States.  The transfer of this information to the United States is necessary for the performance of our contract for use of the Platform.</p>
                        <p className={paragraph}>When we transfer personal data subject to GDPR outside of 259Rx, we use standard contract clauses approved by the EU for this purpose, or another appropriate transfer mechanism. </p>
                        <p className={paragraph}>Note that U.S. law is not equivalent to GDPR.  As of the Last Updated date, the U.S. has not been deemed an “adequate” jurisdiction under GDPR for purposes of international data transfers.  However, the EU and the U.S. are negotiating the terms of an adequacy determination that may go into effect in the years to come.</p>
                        <h3 className={secondHeading}> (d)     Rights under U.S. state law</h3>
                        <p className={paragraph}>This section applies to you only if you reside in California or another U.S. state where applicable law provides for some or all of these rights or substantially similar rights.  Except as noted in this section, we extend these California rights to residents of those states regardless of whether applicable law in those states includes all of these rights.</p>
                        <div className={sectionIndent}>
                            <h3 className={secondHeading}> (i)      Rights under CCPA</h3>
                            <p className={paragraph}>The California Consumer Privacy Act (“CCPA”) provides California consumers with additional rights regarding their personal information (as defined in CCPA). </p>
                            <p className={paragraph}>The categories of personal information we collect depend on how you use the Platform.   They’re generally described in Information We Collect.  For clarity, we have collected personal information for these CCPA categories of business and commercial purposes in the last twelve months:</p>
                            <ul className={listStyle}>
                                <li>·    Advertising and Marketing</li>
                                <li>·    Error Management</li>
                                <li>·    Internal Research</li>
                                <li>·    Provide Products or Platform</li>
                                <li>·    Quality Assurance</li>
                                <li>·    Security</li>
                                <li>·    Short-Term Transient Use</li>
                            </ul>
                            <p className={paragraph}>The disclosures under Purposes describe with business purpose or commercial purpose for those disclosures of information.  The categories of third parties to whom we disclose and sell or share personal information are under with your consent or at your request above. </p>
                            <p className={paragraph}>Under CCPA, California consumers have the following rights:</p>
                            <div className={'ml-10 py-4'}>
                                <h3 className={secondHeading}> (1)     Rights to Know, Access, Correct and Delete.</h3>
                                <p className={paragraph}>You have the right to request that we disclose, correct and delete personal information about you that we have collected.  Your right to know includes the personal information we have sold or shared or disclosed for a business purpose or a commercial purpose. </p>
                                <p className={paragraph}>Finally, we may deny deletion requests, in whole or in part, with respect to information we reasonably need to:</p>
                                <ul className={listStyle}>
                                    <li>·    comply with legal obligations;</li>
                                    <li>·    allow you, other consumers, or us to exercise free-speech rights or other legal rights;</li>
                                    <li>·    complete an obligation that you have requested (for instance, if a product you purchased carries a warranty or could reasonably be subject to recall under applicable law); or</li>
                                    <li>·    if we use the information only for internal purposes reasonably aligned with consumer expectations.</li>
                                </ul>
                                <h3 className={secondHeading}> (2)     Rights to Limit and Opt-Out of Sharing/Sale</h3>
                                <p className={paragraph}>You also have the right to direct us (1) not to share or sell your personal information and (2) limit our disclosure and use of your sensitive personal information to purposes necessary to provide the Platform to you. </p>
                                <p className={paragraph}>We don’t offer online mechanisms for exercising these rights, as we don’t sell or share your personal information and we always limit our use of any sensitive information that you provide us to necessary uses consistent with the purpose of your disclosure.</p>
                                <p className={paragraph}>For clarity, as of the Last Updated date, we have no knowledge of any use of personal information we collect from individuals under the age of 18 for ‘sale’ or ‘sharing’ purposes.</p>
                                <h3 className={secondHeading}> (3)     Right of No Retaliation</h3>
                                <p className={paragraph}>CCPA prohibits us from discriminating against you if you exercise rights under CCPA, except when you opted in to a financial incentive involving certain of your personal information, and subsequently restrict our use of that personal information through a CCPA rights request. </p>
                                ·    For example, if we offer you a discount code for consenting to receive marketing emails, and you then require us to delete your email address, we may not honor that discount code.
                                <p className={paragraph}>Your right of no retaliation doesn’t need to be exercised.  We never retaliate against anyone exercising their rights under this Policy or CCPA.</p>
                                <h3 className={secondHeading}> (4)     Request process for CCPA rights</h3>
                                <p className={paragraph}>To submit a CCPA request relating to the foregoing rights, please contact us with “California Privacy Rights Request” in the subject line of your email or the beginning of your letter.  The process described under Requesting information generally applies to requests to exercise CCPA rights.  However, in addition:</p>
                                <ul className={listStyle}>
                                    <li className={bulletStyle}>·    A California resident's authorized agent may submit CCPA rights request.</li>
                                    <li className={secondIndent+' '+bulletStyle}>»   Requests submitted by an authorized agent will still require verification of the person who is the subject of the request in accordance with the process described below. We will also ask for proof that the person who is the subject of the request authorized an agent to submit a privacy request on their behalf.</li>
                                    <li className={secondIndent+' '+bulletStyle}>»   An authorized agent that has power of attorney pursuant to California Probate Code section 4121 to 4130 must submit proof of statutory power of attorney, but consumer verification is not required.</li>
                                    <li className={bulletStyle}>·    We will acknowledge your request within 10 days, and our goal is to fulfill your request within 45 days.  However, we may you that we will require up to 45 further days to fulfill your request, along with an explanation of why our response is delayed. </li>
                                    <li className={bulletStyle}>·    We provide responses in the manner we receive your request (i.e., with an email response to an email request).</li>
                                </ul>
                            </div>
                            <h3 className={secondHeading}> (ii)     Other California law</h3>
                            <p className={paragraph}>Because we only give your information to third parties for direct marketing purposes if you expressly consent, and always allow you to opt out of direct marketing communications after opting in, we believe we are not currently required to comply with California Civil Code Section 1798.83. </p>
                        </div>
                        <h3 className={secondHeading}> (e)     Requesting information</h3>
                        <div className={sectionIndent}>
                            <h3 className={secondHeading}> (i)      Submitting requests</h3>
                            <p className={paragraph}>To exercise any rights described in this Policy, please contact us.  Your request must:</p>
                            <ul className={listStyle}>
                                <li className={bulletStyle}>·    provide sufficient information to identify you and the law that applies to you, such as your name, e-mail address, home or work address, or other information we maintain. </li>
                                <li className={bulletStyle}>·    not include social security numbers, driver’s license numbers, third-party account numbers, credit or debit card numbers, or health information.</li>
                            </ul>
                            <h3 className={secondHeading}> (ii)     Verifying requests</h3>
                            <p className={paragraph}>We verify requests by first confirming the source of the request and then by matching the information submitted to the information we maintain.  If your request is unclear or we are unable to authenticate your identity, we will respond with direction on how to remedy the deficiencies, in accordance with law that applies to you. </p>
                            <p className={paragraph}>If we cannot verify the identity of the individual making the request, we may deny it, in full or in part.</p>
                            <h3 className={secondHeading}> (iii)    Responses to requests</h3>
                            <p className={paragraph}>We will respond to your request as quickly as we can, taking into account the nature of your request and the volume of pending requests.  The content of our response will vary with the nature of your request, but will always respond in accordance with any deadlines or requirements specified by the laws that applies to you.</p>
                            <p className={paragraph}>Under certain circumstances, we may be unable to provide responsive personal information, such as when disclosure would create a substantial, articulable and unreasonable risk to the security of the information, customers’ Accounts, or the security of our systems or networks.  We do not disclose Account passwords or any other non-personal information that enables access to an Account.</p>
                            <p className={paragraph}>Please understand, however, that we reserve the right to retain an archive of any deleted information, to the extent permitted by law.  We may also retain deidentified or aggregate data derived from information about you.</p>
                        </div>
                        <h3 id='outsideTen' ref={ref => scrollRef.current.outsideTen = ref } className={headingStyle}>10.  Outside Materials</h3>
                        Some areas of the Platform contain links to third-party websites, resources and advertisers (“Outside Materials”). Outside Materials are not part of the Platform. We do not control (and are not responsible for) third party content or privacy practices.  Information you provide to third parties is not covered by this Policy.  These third parties have their own policies and practices about data, which may include what information they share with us, your rights and choices on their services and devices, and where they store information.  We encourage you to familiarize yourself with their privacy notices and applicable contractual terms.

                    </div>
                </div>
            </div>   
        </>
    );
};