import React, { useContext } from 'react';
import Toggle from 'react-toggle';
import { handleGlobalToggle } from '../../utils/globalHandlers';
import { UseToggleContext } from '../../context/ToggleContexts';

export default function AssumptionTable({ reference, dataContext, bool, setBool, changeHandler, excludeHedge }) {

    const toggler = UseToggleContext();

    function toggleChange(e) {
        setBool(!bool)
        if (changeHandler) changeHandler(e)
    }
    return (
        <table ref={reference} id="assumptionsTable" className="w-full p-20">
            {/* these headers keep disappearing once dropdown opens and are not aligned */}
            <thead>
                <tr className="">
                    <th className="text-left py-1">Assumption</th>
                    <th className="">Preset Value</th>
                    <th className="">User Value</th>
                </tr>
            </thead>
            <tbody >
                {Object.keys(dataContext).map((keys, idx) => (
                    <React.Fragment key={`${idx}: Assumptions list: ${keys} `}>
                        {keys.includes("apply improvement year 1")?
                            <tr key={`Assumption key: ${keys}, index: ${idx}`}>
                                <td className=" whitespace-pre-wrap w-[50%] tracking-tighter ">
                                    {keys}
                                </td>
                                <td className="text-center uppercase">
                                    {`${dataContext[keys]==0?false:true}`}
                                </td>
                                <td className="flex items-center justify-center">
                                    <input 
                                            name={keys}
                                            // defaultValue={context?.settings?.assumptions[keys]==0?false:true}
                                            value={bool?1:0}
                                            type="number"
                                            className="hidden"
                                            onInput={(e) => { console.log("React:onChange"); }}
                                        />
                                
                                    <Toggle
                                        id='assumptions-toggle'
                                        defaultChecked={dataContext[keys]==0?false:true}
                                        onChange={toggleChange} 
                                        className='my-2'
                                    />
                                
                                </td>
                            </tr>
                        :
                            <tr key={`Assumption key: ${keys}, index: ${idx}`}>
                                <td className=" whitespace-pre-wrap w-[50%]  tracking-tighter ">
                                    {keys}
                                </td>
                                <td className="text-center">
                                    {dataContext[keys]}
                                </td>
                                <td className='flex justify-center'>
                                    <input 
                                        name={keys}
                                        defaultValue={dataContext[keys]}
                                        type="number" 
                                        min="0" 
                                        className="border rounded py-1 px-1 my-2 w-20  text-right "
                                        onChange={changeHandler?changeHandler:null}  
                                        step={0.01}
                                    />
                                </td>
                            </tr>
                        }
                    </React.Fragment>
                ))}    
               {excludeHedge&&<tr >
                    <td className=" whitespace-pre-wrap w-[50%]  tracking-tighter py-3">
                        Exclude hedge from margin
                    </td>
                    <td>

                    </td>
                    <td className='flex justify-center'>
                        <input 
                            type="checkbox" 
                            min="0" 
                            className="border rounded py-1 px-1 my-4 w-20  text-center "
                            defaultChecked={toggler.toggle.exludeHedgeFromMargin}
                            onChange={() => handleGlobalToggle(toggler.setToggle, "exludeHedgeFromMargin", toggler.toggle.exludeHedgeFromMargin)}
                        />
                    </td>
                </tr>}
            </tbody>
        </table>
    )
};