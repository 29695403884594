import React, { useContext, useEffect, useState } from "react";
import { UseContextClues } from '../../context/ContextClues';
import { UseToggleContext } from '../../context/ToggleContexts';
import { restructureData, sumTotals, transformData } from "../../utils/resultsSummary";
import SummaryTables from "../Tables/SummaryTables";
import { addGrossNetValues, bothTotals, divideDeltaArray } from "../../utils/pricingLens";
import LockedMessage from "../Error/LockedMessage";

export default function Summary({reference}){

    const context = UseContextClues();
    const toggler = UseToggleContext();

    const [data, setData] = useState({
        results : [],
        target : [],
        altered_results : {},
        altered_target : {},
        brand_wac_results : [],
        gross_wac_results : [],
        net_wac_results : [],
        brand_wac_target : [],
        gross_wac_target : [],
        net_wac_target : [],
    });

    useEffect(() => {
        setData({
            results:sumTotals(restructureData(transformData(JSON.parse(context.results["Rebates_summary"])))),
            target:sumTotals(restructureData(transformData(JSON.parse(context.target["Rebates_summary"])))),
            altered_results: bothTotals("results", context),
            altered_target: bothTotals("target", context),
            brand_wac_results: sumTotals(restructureData(transformData(JSON.parse(context.results["Brand_WAC_Summary"]))),true),
            gross_wac_results: divideDeltaArray(sumTotals(restructureData(transformData(JSON.parse(context.results["Rebates_summary"])))), sumTotals(restructureData(transformData(JSON.parse(context.results["Brand_WAC_Summary"]))),true)),
            net_wac_results: divideDeltaArray(Object.values(bothTotals("results", context)), sumTotals(restructureData(transformData(JSON.parse(context.results["Brand_WAC_Summary"]))),true)),
            brand_wac_target: sumTotals(restructureData(transformData(JSON.parse(context.target["Brand_WAC_Summary"]))), true),
            gross_wac_target: divideDeltaArray(sumTotals(restructureData(transformData(JSON.parse(context.target["Rebates_summary"])))), sumTotals(restructureData(transformData(JSON.parse(context.target["Brand_WAC_Summary"]))), true)),
            net_wac_target: divideDeltaArray(Object.values(bothTotals("target", context)), sumTotals(restructureData(transformData(JSON.parse(context.target["Brand_WAC_Summary"]))), true)),
        });
    }, [context.results, context.target, context.pricingLens ]);

    const givenResults = [
        {header:"Brand WAC", data: data?.brand_wac_results},
        {header:"Gross", data: toggler.toggle.exludeHedgeFromMargin ? data?.results : context.pricingLens.results.percentageByYear? divideDeltaArray(data.results, Object.entries(context.pricingLens.results.passBackbyYear).map(el => el[1])) : data?.results.map(el => el/(context.pricingLens.results?.priceLens?.passBack))},
        {header:"% of Brand WAC", data: data.gross_wac_results, percentage: true},
        {header:"Net", data:Object.entries(data.altered_results), obj:true },
        {header:"% of Brand WAC", data: data.net_wac_results, percentage:true },
        {header:"Margin $", data:addGrossNetValues(data?.results, Object.entries(data.altered_results))},
        {header:"Margin %", data: divideDeltaArray(addGrossNetValues(data?.results, Object.entries(data.altered_results)), data.results), percentage: true},
    ];
    const givenTargets = [
        {header:"Brand WAC", data: data?.brand_wac_target},
        {header:"Gross", data: toggler.toggle.exludeHedgeFromMargin ? data?.target : context.pricingLens.target.percentageByYear ? divideDeltaArray(data.target, Object.entries(context.pricingLens.target.passBackbyYear).map(el => el[1])) : data?.results.map(el => el/(context.pricingLens.target?.priceLens?.passBack))},
        {header:"% of Brand WAC", data: data.gross_wac_target, percentage: true},
        {header:"Net", data:Object.entries(data.altered_target), obj:true },
        {header:"% of Brand WAC", data: data.net_wac_target, percentage: true },
        {header:"Margin $", data:addGrossNetValues(data?.target, Object.entries(data.altered_target))},
        {header:"Margin %", data:  divideDeltaArray(addGrossNetValues(data?.target, Object.entries(data.altered_target)), data.target), percentage: true},
    ];
    
    return (
        <div ref={reference}>
            <LockedMessage />
            <SummaryTables  givenData={givenResults} table={"standard"}/>  
            <LockedMessage />
            <SummaryTables  givenData={givenTargets} table={"target"}/>  
        </div>
    )
};