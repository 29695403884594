import React, { useState } from 'react';
import { deleteUserTable } from '../../utils/claimsLoad';
import { downloadCase } from '../../utils/loadSave';

export default function SimpleButton({tableName, setModels, download}) {
    
    const [ load, set ] = useState(false);

    return (
        <>
            {download?
            <button type='button' className='text-xs uppercase border bg-light-blue hover:bg-hover-blue px-5 py-2 text-white font-bold rounded hover:shadow-lg' onClick={() => downloadCase(tableName, setModels, set)}>
                {load?<l-tail-chase size={"15"} speed="1.75" color="white" ></l-tail-chase>:"download"}
            </button>:
            <button type='button' className='w-full py-3 hover:bg-primary-red h-full uppercase' onClick={() => deleteUserTable(tableName, setModels, set)}>
                {load?<l-tail-chase size={"15"} speed="1.75" color="black" ></l-tail-chase>:"delete"}
            </button>
            }
        </>
    )
};