import React from 'react';
import { formatCurrency } from '../../utils/analytics';
import LineBar from '../Charts/LineBar';

export default function AnalyticsDrugsTable({tableStyles, viewGraph, headerStyles, useNewData }) {
    return (
        <>
            <table className={tableStyles}>
                <thead>
                    {viewGraph.drugs&&<tr>
                        <td className={headerStyles}>
                            drug name
                        </td>
                        <td className={headerStyles}>
                            category
                        </td>
                        <td className={headerStyles+" text-right"}>
                            Year 1 Rebates
                        </td>
                        <td className={headerStyles+" text-right pl-2"}>
                            % of total
                        </td>
                    </tr>}
                </thead>
                <tbody>
                    {viewGraph.drugs?useNewData.drugs?.map((row, idx) => (
                        <tr key={`Top 20 Drugs key: ${row.drugname}, index: ${idx}`}>
                            <td>
                                {row.drugname}
                            </td>
                            <td>
                                {row.category}
                            </td>
                            <td className='text-right'>
                                {formatCurrency(row.value)}
                            </td>
                            <td className='text-right'>
                                {(row.percentage).toFixed(1)}%
                                {/* {formatCurrency(row.value)} */}
                            </td>
                        </tr>
                    )):
                    <tr>
                        <td colSpan={3} className='flex justify-center'>
                            <LineBar data={useNewData.drugs} teller={"drugname"} bar={'percentage'} crossLine={true}/>
                        </td>
                    </tr>
                    }
                </tbody>
            </table>
        </>
    )
};