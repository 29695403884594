import {extractFromLocal} from "./loadSave";

export async function createFormulary ({formularyId, baseModel}, addData) {
    if (formularyId && baseModel) {
        try {
            const response = await fetch("/api/create_formulary", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${extractFromLocal().accessToken}`,
                },
                body: JSON.stringify({
                    formulary_id: formularyId,
                    base_model: baseModel
                })
            });
            const data = await response.json();

            if (data.status === 'success') {
                alert(data.message);
                // Refresh the dropdowns to include the new formulary
                // fetchAndPopulateFormularyDropdowns('baseFormulary');
                // fetchAndPopulateFormularyDropdowns('targetFormulary');
                // fetchAndPopulateFormularyDropdowns('useBaseModel');
                // fetchAndPopulateFormularyDropdowns('formulary');
                fetchAndLoadFormulary(addData)
            
            } else {
                alert("Error: " + data.message);
            }
        } catch (error) {
            console.error("Error creating formulary:", error);
            alert("Failed to create formulary. Check the console for more details.");
        }
    } else {
        alert('Please select a base model and enter a new formulary name.');
    }
};

export async function fetchAndLoadFormulary(addData) {
    try {
        const response = await fetch("/api/get_formularies", {headers: {'Content-Type': 'application/json',
        Authorization: `Bearer ${extractFromLocal()?.accessToken}`}});
        const formularyNames = await response.json();

        if (Array.isArray(formularyNames)) {
            addData(formularyNames);
        }
    } catch (error) {
        console.error("Error fetching formulary names:", error);
    }
};


export async function importFormularyRate(formname, importedData, setLoading, errorMessage, activate, toggler, context ){
    try {
        
        const formData = new FormData();
        formData.append("csvfile", importedData);
        
        const data = await fetch(`/api/importFormularyRateFile/${formname}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${extractFromLocal().accessToken}`,
            },
            body: formData
        });

        let response = await data.json();

        if (data.ok) {
            // console.log(response);
            activate("importFormulary", toggler.toggle, toggler.setToggle, false)
            context.addFormularies([
                ...context.formularies,
                formname
            ]);
        } else {
            errorMessage(response.detail);
        }
        setLoading(false);


    } catch(err) {
        errorMessage(err);
    };
};

export function formatBytes(a,b=2){if(!+a)return"0 Bytes";const c=0>b?0:b,d=Math.floor(Math.log(a)/Math.log(1024));return`${parseFloat((a/Math.pow(1024,d)).toFixed(c))} ${["Bytes","KB","MB","GB","TB","PB","EB","ZB","YB"][d]}`}