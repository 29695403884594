import { extractFromLocal, loadSaved } from "./loadSave";

export async function login({username, password, scope}, account) {

    const formData = new FormData();
    formData.append("scope", scope)
    formData.append("username", username)
    formData.append("password", password)

    try {
        const data = await fetch("/token", {
            method: "POST",
            body: formData
        })

        var accessToken = undefined;
        
        if (data.ok) {
            const results = await data.json();
            accessToken = results.access_token;
        }

        var results = undefined;

        if (accessToken) {
            const data = await fetch('/users/me/', {
                method: "GET",
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                }
            });
            if (data.ok) {
                results = await data.json()

                sessionStorage.setItem('259rx_data', JSON.stringify({
                    accessToken,
                    casenumber: extractFromLocal()?.casenumber?extractFromLocal().casenumber:"",
                    loggedInAt: new Date()
                }));

                account.setUser({
                    loggedIn: true,
                    token: accessToken,
                    userData: results
                });
                return results
            };
        };


    } catch (err) {
        console.log(err)
    };
};


export async function checkUser(setUser, context, toggler){

    try {
        var results = undefined;
    
        const data = await fetch('/users/me/', {
            method: "GET",
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${extractFromLocal()?.accessToken}`,
            }
        });

        if (data.ok) {
            results = await data.json()
            setUser({
                loggedIn: true,
                token: extractFromLocal()?.accessToken,
                userData: results
            });
            if (extractFromLocal()?.claimnumber) {
                loadSaved(extractFromLocal().claimnumber, context, toggler)
            }
            return results
        } else {
            return await data.json()
        }
    } catch(err) {
        console.log(err)
    };

};

export async function signup(payload, setMessage){

    const userpayload = 
        {
            "username": payload.username,
            "email": payload.email,
            "full_name": `${payload.first_name} ${payload.last_name}`,
            "user_type": payload.user_type,
            "password": payload.password,
            "orgid": payload.orgid
        };

    try {
        const data = await fetch("/api/add_user", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${extractFromLocal()?.accessToken}`,
            },
            body: JSON.stringify(userpayload)
        });
        let response = await data.json();

        if (data.ok) {
            return response;
        }else {
            setMessage(response.detail);
        };

    } catch (err) {
        console.log(err)
    }
};

export async function getUserLogs(){

    try {
        const data = await fetch("/api/login-logs", {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${extractFromLocal()?.accessToken}`,
            },
        });
        let response = await data.json();

        if (data.ok) {
            return response;
        }

    } catch (err) {
        console.log(err)
    }
};

export async function getOrgTags(set){
    
    try {
        const data = await fetch("/api/get_org_tags", {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${extractFromLocal()?.accessToken}`,
            },
        });
        let response = await data.json();

        if (data.ok) {
            if (response.length>0) {
                set(response.map(ele => ({value: ele, label: ele})))
            }else {
                set(response);
            }

        }

    } catch (err) {
        console.log(err)
    };
};