import React from 'react';

export default function CrystalBreakevenTable({ breakEvenGrid, payload}) {
    
    const tBodyStyle = "py-1 px-2 h-12 box-content text-xs";
    
    return (
        <table className='text-sm w-fit ml-1'>
            <thead className='text-left'>
                <tr className='sticky top-0 bg-background-light'>
                    <th className='align-bottom text-center whitespace-pre-wrap pl-1'>WAC</th>
                    <th className='text-center align-bottom whitespace-pre-wrap'>Rebates</th>
                    <th className='text-center align-bottom whitespace-pre-wrap'>Rate</th>
                    <th className='text-center align-bottom whitespace-pre-wrap'>Rxs</th>
                    <th className='text-right whitespace-pre-wrap min-w-[65px] pr-1'>Market Share %</th>
                </tr>
            </thead>
            <tbody className='border bg-white overflow-y-auto'>
                {breakEvenGrid?.grid.map((rows, idx) => (
                    <tr key={`Crystal ball key: ${rows.drugname}, index: ${idx}`} className='border'>
                        <td className={tBodyStyle}>{parseFloat(rows.WAC).toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0, minimumFractionDigits: 0 })}</td>
                        <td className={tBodyStyle+" text-center"}>{parseFloat(rows.rebates).toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0, minimumFractionDigits: 0 })}</td>
                        <td className={`text-center ${payload?.[rows?.mfr]?.[rows?.drugname.split(" ").join("_")]?.starting_rate.toFixed(4) == rows.rate.toFixed(4)?"":"text-primary-red font-extrabold"}`}>{parseFloat(rows.rate).toLocaleString('en-US', { style: 'percent', minimumFractionDigits: 2 })}</td>
                        <td className={tBodyStyle+" text-center"}>{parseFloat(rows.rxs).toLocaleString('en-US', { maximumFractionDigits: 0 })}</td>
                        <td className={tBodyStyle+" text-right pr-1"}>{parseFloat(rows.marketshare).toLocaleString('en-US', { style: 'percent', minimumFractionDigits: 2 })}</td>                    
                    </tr>
                ))}
            </tbody>
        </table> 
    );
};