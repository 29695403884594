import React, { useContext, useEffect, useRef, useState } from 'react';
import { UseToggleContext } from '../../context/ToggleContexts';
import EventForm from '../Forms/EventForm';
import EventsTable from '../Tables/EventsTable';
import EventGuide from '../UserGuide/EventGuide';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo, faFileExcel, faFilePdf, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import CustomHover from '../Hover/CustomHover';
import { globalBoolHandler } from '../../utils/globalHandlers';
import { downloadToExcelGlobal } from '../../utils/download';


export default function Events() {

    const toggler = UseToggleContext();

    const downloadRef = useRef();

    const [ target, setTarget ] = useState('');
    const [ drugs, setDrugs ] = useState(null);
    const [ help, askHelp ] = useState(false);
    const [ hover, setHover ] = useState({
        basis: false,
        magnitude: false,
    });

    return (
        <>
             <div className={`fixed right-0 bottom-0 top-[100px] w-[1000px] bg-white z-[50] py-3 pl-6 pr-8 transition-all duration-500 shadow-xl ${toggler.aux ==='events' ? " translate-x-0 ":"translate-x-[2000px] "}`}>
                <div style={{height: toggler.pageHeight-110}} className='overflow-y-auto pr-8'>
                    <div className='relative object-contain row-span-2 ' id="events" >
                        <div className='flex justify-between'>
                            <div className='text-lg font-bold pb-2 '>Events</div>
                            <div>
                                <button className='pr-6 hover:underline' onClick={() => globalBoolHandler("pipelineEvents", toggler.toggle, toggler.setToggle, !toggler.toggle.pipelineEvents)} >Pipeline beta</button>
                                <button aria-label='download to excel' type='button' onClick={() => downloadToExcelGlobal(downloadRef, toggler.setStatus)} className='text-lg font-bold pb-2 pr-6 '>
                                    <FontAwesomeIcon className={'text-xl text-light-blue hover:text-hover-blue'} icon={faFileExcel} />
                                </button>
                                <button aria-label='toggle help window' type='button' onClick={() => {askHelp(!help)}} className='text-lg font-bold pb-2 '>
                                    <FontAwesomeIcon className={'text-xl text-light-blue hover:text-hover-blue'} icon={faCircleInfo} />
                                </button>
                            </div>
                        </div>
                        {/* <!-- user guide popup --> */}
                            {help&&<EventGuide />}
                        {/* <!-- Form to create a new event --> */}
                            <EventForm {...{drugs, setDrugs, target, setTarget }}/>
           
                        {/* <!-- Table to display events --> */}
                            <EventsTable {...{ setDrugs, target, hover, setHover, reference:downloadRef }} /> 
                        {/* too tips */}
                            <CustomHover {...{hovered:hover.magnitude, right:"right-[145px]", top: "bottom-[-20px]", width:"", text:"text-sm text-center" }}  >
                                Enter a positive number for an increase, 
                                <br/>
                                or a negative number for a decrease.
                            </CustomHover>
                            <CustomHover {...{hovered:hover.basis, right:"right-[65px]", top: "bottom-[-20px]", width:"", text:"text-xs text-center" }}  >
                                Enter ‘relative’ for a % increase or decrease. 
                                <br />
                                    <i>E.g. -50 relative will result in a reduction by half. </i>
                                <br />
                                <br />
                                Enter ‘absolute’ for a volume increase or decrease. 
                                <br />
                                <i>E.g. 4 absolute will result in a value pickup of 4.</i>
                            </CustomHover>
                    </div>
                </div>
            </div>
        </>
    )
};