import React, { useContext, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { UseContextClues } from '../../context/ContextClues';
import { UseUserContext } from '../../context/UserContext';
import { UseToggleContext } from '../../context/ToggleContexts';
import { globalBoolHandler } from '../../utils/globalHandlers';
import { faAsterisk, faBars, faPlus, faSave, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Timer from '../Timer/Timer';
import NavbarDropdown from './NavbarDropdown';
import rxstestimage from './rxstest.png'
import MobileNavbar from './MobileNavbar';
import ReabateOp from '../SVG/RebateOp';
import Logo259 from '../SVG/Logo259';


export default function Navbar ({reverse, userActivity, actionCounter, remainingTime})  {

    const location = useLocation()
    const account = UseUserContext();
    const context = UseContextClues();

    const toggler = UseToggleContext();

    const [ dropped, toggle ] = useState(false);
    const [ mobile, toggleMobile ] = useState(false);
    const [ mouseHover, onMouseHover ] = useState(false);

    function handleDropDown(){
        toggle(!dropped);
    };

    function handleSave(e){
        e.stopPropagation();
        globalBoolHandler("saveMemo", toggler.toggle, toggler.setToggle, !toggler.toggle.showMemo);
        // saveCase(context, true);
    };
    
    function handleToggle(){

        if (location.pathname==="/") {
            toggler.setToggle(toggles => ({
                ...toggles,
                ["mobileSidePanel"]: !toggler.toggle.mobileSidePanel
            }));
        };
        if (mobile) {
            toggleMobile(false);
        };
    };

    return (
        <>
            <div className={` ${reverse?reverse:"bg-primary-dark"}  text-sm sticky top-[-10] left-0 z-[100] overflow-hidden ${account.user?.loggedIn&&location.pathname==="/"?"shadow-xl":!account.user?.loggedIn&&(location.pathname==="/"||location.pathname.includes('privacy'))?"":"shadow-xl"}` }>
                {/* this is the left logo button  */}
                <Link to={"/"} className={`absolute top-[0px] md:left-4 w-32 ml-2`} onClick={handleToggle} onMouseEnter={() => onMouseHover(true)} onMouseLeave={() => onMouseHover(false)}>
                    {window.location.href.toLowerCase().includes("app.rebateoptimizer.com")?<ReabateOp switchColors={mouseHover?"#107D93":"#ffffff"} scale={"scale(1.5)"} className={"translate-y-[-15px]"}/>
                    :<Logo259 className={"translate-y-[20px] ml-8"} hovered={mouseHover}/>}
                </Link>
                {/* this is logged out users navbar */}
                <ul className='h-[100px] flex gap-3 pr-2 md:pr-8 ml-60  items-center justify-end'>
                    {!account.user?.userData.username&&toggler.isLoading&&
                        <div className='hidden md:flex p-9 gap-3 '>
                            <Link to={"/about"} className='hover:text-hover-blue text-white'>About</Link>
                            {/* <Link to={"/contact"} className='hover:text-hover-blue text-white'>Contact Us</Link> */}
                            <Link to={"/login"} className='hover:text-hover-blue text-white'>Log in</Link>
                        </div>
                    }
                    <button aria-label='toggle mobile menu'  type='button' onClick={() => toggleMobile(!mobile)}>
                        <FontAwesomeIcon className='text-2xl px-4 text-white md:hidden' icon={faBars}/>
                    </button>
                    {/* this is the home page navbar */}
                    {account.user?.userData.username&&toggler.isLoading&&<>
                        {toggler.toggle?.showTime&&<button aria-label='show log in form to refresh user authentication' className='flex item-center text-white gap-2' type='button' onClick={() => globalBoolHandler("auxLogin", toggler.toggle, toggler.setToggle, !toggler.toggle.auxLogin)}>
                            Logging out in: <Timer  />
                        </button>}
                        {location.pathname==="/"&&<button type='button' aria-label='open panel to create a new project case' className="relative text-white hover:text-gray-700 disabled:cursor-not-allowed disabled:text-gray-700" onClick={() => globalBoolHandler("saveLoad", toggler.toggle, toggler.setToggle, !toggler.toggle.saveLoad)} >
                            <FontAwesomeIcon className='text-xl px-4' icon={faPlus}/>
                        </button>}
                        {location.pathname==="/"&&context.results&&context.target&&<button type='button' aria-label='save current case progress' className="relative text-white disabled:cursor-not-allowed disabled:text-gray-700 hover:text-gray-700" onClick={handleSave} disabled={context.settings.locked}>
                            {!context.settings.savedCase&&<FontAwesomeIcon className=' absolute text-xs px-4 right-5 top-[-5px] text-primary-yellow' icon={faAsterisk}/>}
                            <FontAwesomeIcon className='text-xl px-4' icon={faSave}/>
                        </button>}
                        {location.pathname==="/"&&context.casenumber&&<button type='button' aria-label='toggle load/save panel' className='text-white pr-2 hover:text-gray-700' onClick={() => globalBoolHandler("loadSave", toggler.toggle, toggler.setToggle, !toggler.toggle.loadSave)}>
                            <span className='font-medium'>CASE:</span> {context.casenumber}
                        </button>}
                        {location.pathname==="/"&&context.mainData&&<div className='flex items-center h-[100px]'>
                            <div className='text-white pr-2' >
                                <span className='font-medium'>DATA:</span> {context.settings.claims.tName}
                            </div>
                        </div>}
                        {/* this is the navbar profile */}
                        <div className='flex gap-4 h-[100px] items-center' onMouseEnter={() => toggle(true)} onMouseLeave={() => toggle(false)}>
                            <div className='border w-8 h-8 rounded-full flex items-center justify-center' onClick={handleDropDown}>
                                {account.user?.userData?.username.toLowerCase()==="rxtest"?<img src={rxstestimage} className=' rounded-full' />:<FontAwesomeIcon className='text-md text-white' icon={faUser}/>}
                            </div>
                            <div className='text-white font-medium'>
                                {account.user?.userData.username}
                            </div>
                        </div>

                    </>}
                </ul>
            </div>
            {account.user?.userData.username&&toggler.isLoading&&dropped&&<div onMouseEnter={() => toggle(true)} onMouseLeave={() => toggle(false)} >
                <NavbarDropdown />
            </div>}
            <MobileNavbar mobile={mobile} toggleMobile={toggleMobile} reverse={reverse} />
        </>
    )
};