import React, { useContext } from "react";
import { UseContextClues } from '../../context/ContextClues';

export default function VolumeComparisonTable() {

    const context = UseContextClues();

    return (
        <table className="w-full text-sm">
            <thead>
                <tr>
                    <th>
                        Volume Comparison
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        Coverage %
                    </td>
                    <td className="text-right">
                        {(context.mainData?.volume_comparison.comparison?.coverage_percentage/100).toLocaleString(undefined, {style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </td>
                </tr>
                <tr>
                    <td>
                        Total New Claims (Full)
                    </td>
                    <td className="text-right">
                        {context.mainData?.volume_comparison?.comparison?.total_net_claims_full.toLocaleString(undefined, {style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                    </td>
                </tr>
                <tr>
                    <td>
                        Total New Claims (Selected)
                    </td>
                    <td className="text-right">
                        {context.mainData?.volume_comparison?.comparison?.total_net_claims_selected.toLocaleString(undefined, {style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                    </td>
                </tr>
            </tbody>
        </table>
    )
};