import React, { useContext, useState } from 'react';
import { UseContextClues } from '../../context/ContextClues';

export default function QuickYear() {

    const currentYear = new Date().getUTCFullYear();

    const [ quickView, selectQuarter ] = useState(false);

    const context = UseContextClues();

    function handleQuickSelect(quarter, e, digit) {
        e.preventDefault()
        var today = new Date();
        var year = today.getFullYear();

        var starting
        var ending

        if (quarter === 'first') {
            starting = year+digit+"-01-01"
            ending = year+digit+"-03-31"
        } else if (quarter === "second") {
            starting = year+digit+"-04-01"
            ending = year+digit+"-06-30"
        } else if (quarter === "third") {
            starting = year+digit+"-07-01"
            ending = year+digit+"-09-30"
        } else if (quarter === "fourth") {
            starting = year+digit+"-10-01"
            ending = year+digit+"-12-31"
        }

        selectQuarter(false);
        context.setSettings({
            ...context.settings,
            locked: true,
            savedCase: false,
            claims: {
                ...context.settings.claims,
                startDate: starting,
                endDate: ending,
            },
        })
    };

    return (
        <>
            <button type="button" onClick={() => selectQuarter(!quickView)} className="float-right">Quick</button>
            {quickView&&<div className="absolute z-50 bg-white border right-[0px] text-right flex flex-col rounded shadow-xl h-[200px] overflow-y-auto">
                <button type="button" onClick={(e) => handleQuickSelect("first", e, 0)} className="py-2 px-3 hover:rounded-t hover:bg-hover-blue hover:text-primary-light uppercase">1Q{currentYear-2000}</button>
                <button type="button" onClick={(e) => handleQuickSelect("second", e, 0)} className="py-2 px-3  hover:bg-hover-blue hover:text-primary-light uppercase">2Q{currentYear-2000}</button>
                <button type="button" onClick={(e) => handleQuickSelect("third", e, 0)} className="py-2 px-3  hover:bg-hover-blue hover:text-primary-light uppercase">3Q{currentYear-2000}</button>
                <button type="button" onClick={(e) => handleQuickSelect("fourth", e, 0)} className="py-2 px-3 hover:rounded-b hover:bg-hover-blue hover:text-primary-light uppercase">4Q{currentYear-2000}</button>
                <button type="button" onClick={(e) => handleQuickSelect("first", e, -1)} className="py-2 px-3 hover:rounded-b hover:bg-hover-blue hover:text-primary-light uppercase">1Q{currentYear-2001}</button>
                <button type="button" onClick={(e) => handleQuickSelect("second", e, -1)} className="py-2 px-3 hover:rounded-b hover:bg-hover-blue hover:text-primary-light uppercase">2Q{currentYear-2001}</button>
                <button type="button" onClick={(e) => handleQuickSelect("third", e, -1)} className="py-2 px-3 hover:rounded-b hover:bg-hover-blue hover:text-primary-light uppercase">3Q{currentYear-2001}</button>
                <button type="button" onClick={(e) => handleQuickSelect("fourth", e, -1)} className="py-2 px-3 hover:rounded-b hover:bg-hover-blue hover:text-primary-light uppercase">4Q{currentYear-2001}</button>
                <button type="button" onClick={(e) => handleQuickSelect("first", e, -2)} className="py-2 px-3 hover:rounded-b hover:bg-hover-blue hover:text-primary-light uppercase">1Q{currentYear-2002}</button>
                <button type="button" onClick={(e) => handleQuickSelect("second", e, -2)} className="py-2 px-3 hover:rounded-b hover:bg-hover-blue hover:text-primary-light uppercase">2Q{currentYear-2002}</button>
                <button type="button" onClick={(e) => handleQuickSelect("third", e, -2)} className="py-2 px-3 hover:rounded-b hover:bg-hover-blue hover:text-primary-light uppercase">3Q{currentYear-2002}</button>
                <button type="button" onClick={(e) => handleQuickSelect("fourth", e, -2)} className="py-2 px-3 hover:rounded-b hover:bg-hover-blue hover:text-primary-light uppercase">4Q{currentYear-2002}</button>
            </div>}
        </>
    )
};