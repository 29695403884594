import React, { useContext, useEffect, useState } from 'react';
import { UseToggleContext } from '../../context/ToggleContexts';

export default function StatusBox() {

    const toggler = UseToggleContext();

    const [ loading, setLoading ] = useState(false);

    useEffect(() => {
        setLoading(false);
    }, []);

    return (
        <>
            <div className={`absolute z-[500] text-sm bottom-[80px] right-10 h-[250px] shadow-[rgba(0,0,0,0.2)_-20px_5px_40px_0px] w-[250px] bg-primary-yellow rounded-tl-lg transition-all duration-700 ${(!toggler?.status?.length == 0) ? "translate-y-0 opacity-1" : "translate-y-[248px] opacity-0" }`}>
                <div className='border-b cursor-pointer border-gray-400 p-2 uppercase flex justify-between' onClick={() => setLoading(!loading)}>
                    alert message
                </div>
                <div className='p-2'>
                    {toggler.status}
                </div>
            </div>
        </>
    );
};