import React from 'react';

export default function PipelineList({payload}){
    
    const headers = [
        "category",
        "drugname",
        "entry_mkt_share",
        "startdate",
        "wac_per_unit",
        "unit_per_day",
        "day_per_rx",
        "specialty_ind",
    ];

    const bodyStyles = "border py-3 px-2 tracking-tighter";

    return (
        <>
            <table>
                <thead>
                    <tr className="sticky top-0 bg-white ">
                        {headers.map(header => (
                            <td className="uppercase border-x px-2 text-xs tracking-tighter font-bold text-white bg-black py-2">
                                {header.split('_').join(' ')}
                            </td>
                        ))}
                    </tr>
                </thead>
                <tbody >
                        {payload&&Object.entries(payload).map(data => (
                            <tr className="text-xs border">
                                <td className={bodyStyles+" min-w-[150px]"}>
                                    {data[1].category}
                                </td>
                                <td className={bodyStyles+" min-w-[150px]"}>
                                    {data[1].drugname}
                                </td>
                                <td className={bodyStyles}>
                                    {data[1].entry_mkt_share}
                                </td>
                                <td className={bodyStyles}>
                                    {data[1].startdate}
                                </td>
                                <td className={bodyStyles}>
                                    {data[1].wac_per_unit}
                                </td>
                                <td className={bodyStyles}>
                                    {data[1].unit_per_day}
                                </td>
                                <td className={bodyStyles}>
                                    {data[1].day_per_rx}
                                </td>
                                <td className="text-center">
                                    {data[1].specialty_ind}
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
        </>
    );
};