import React, { useContext } from "react";
import SidePanel from "../components/SidePanel/SidePanel";
import MainSection from "../components/Section/MainSection";
import AuxPanel from "../components/AuxPanel/AuxPanel";
import { UseToggleContext } from '../context/ToggleContexts';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import TinyBarChart from "../components/Charts/TinyBarChart";

export default function HomePage ({}) {

    const toggler = UseToggleContext();
    
    function handleToggle(inputAux) {
        if (toggler.aux && inputAux === toggler.aux) toggler.switchAux('')
        else toggler.switchAux(inputAux)
    };

    return (
        <HelmetProvider>
            <div className="relative hidden md:flex transition-all duration-500">
            {window.location.href.toLowerCase().includes("app.rebateoptimizer.com")?
                <Helmet>
                <meta charSet="utf-8" />
                <title>Rebate Optimizer</title>
                <link rel="canonical" href="http://app.rebateoptimizer.com/#/" />
                </Helmet>:
                <Helmet>
                <meta charSet="utf-8" />
                <title>259Rx</title>
                <link rel="canonical" href="http://259rx.com/#/" />
                </Helmet>
            }
                {toggler.toggle?.mobileSidePanel&&<SidePanel />}
                <MainSection aux={toggler.aux} />
                <AuxPanel handleToggle={handleToggle} />
                {toggler.dateRange?.data&&toggler.dateRange.focus&&
                    <div className="absolute z-[300] border shadow-xl flex flex-col text-xs tracking-tighter pointer-events-none text-primary-yellow bg-primary-dark rounded font-medium" style={{left: toggler.dateRange.location.pageX, top: toggler.dateRange.location.pageY-100}}>
                        <TinyBarChart data={toggler.dateRange.data} />
                    </div>
                }
            </div>
        </HelmetProvider>
    );
};