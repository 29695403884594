import {extractFromLocal} from "./loadSave";

export function loadAssumptions(context) {
    fetch('/api/load_assumptions', {
        method: "GET",
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${extractFromLocal().accessToken}`,
        }
    })
    .then(response => response.json())
    .then(passedData => {
        context.setExclusionSettings(data => ({
            ...data, 
            assumptions: passedData
        }))
    }).then(() => {
        context.setSettings(data => ({
            ...data,
            savedCase: true,
        }))
    })
    .catch(error => console.error('Error:', error));

};

var changedValues = {};

export function saveAssumptionsButton(context, table, toggler) {
    // Send the changedValues object to the server
    var inputs = table.getElementsByTagName('input');
    
    for (var i = 0; i < inputs.length; i++) {
        var input = inputs[i];
        // value = Math.round(value * 100000) / 100000; // Round to 2 decimal places
        var row = input.parentNode.parentNode;
        var assumptionName
        if (input.id.includes("toggle")) {
            // assumptionName=row.cells[0].textContent;
            // do nothing and include the hidden
        }else {
            var value = +input.value;  // make sure it's a float
            assumptionName=row.cells[0].textContent;
        }
        
        changedValues[assumptionName] = value ;
    }
    
    fetch('/api/save_assumptions', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${extractFromLocal().accessToken}`,
        },
        body: JSON.stringify(changedValues)
    })
    .then(response => response.json())
    .then(data => {
        console.log('Assumptions saved:', data);
    })
    .catch(error => console.error('Error:', error));
        toggler.setStatus("Assumptions saved successfully");
        context.setSettings(data => ({
            ...data,
            locked: true,
        }));
        setTimeout(() => {
            loadAssumptions(context);
            toggler.setStatus("");
    }, 2000)
};

export async function getOrgAssumptions(set) {
    try {   
        let data = await fetch('/api/get_org_assumptions', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${extractFromLocal().accessToken}`,
            },
        });
        if (data.ok) {
            let result = await data.json();
            set(result);
        };
    } catch (err) {
        console.log(err);
    };
};

export async function setOrgAssumptions(payload, load) {
    try {
        let data = await fetch('/api/set_org_assumptions', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${extractFromLocal().accessToken}`,
            },
            body: JSON.stringify(payload)
        });
        
        if (data.ok) {
            let response = await data.json()
            alert(response.message)
            getOrgAssumptions(load)
        }
    } catch (err) {
        console.log(err)
    }
};

export function convertToKeyValue(object) {
    let array = []
    Object.keys(object).map(keys => {
        array.push({
            assumption: keys,
            value: object[keys],
        });
    });
    return array;
};