import React, { useContext, useEffect, useState } from 'react';
import { UseContextClues } from '../../context/ContextClues';
import { UseToggleContext } from '../../context/ToggleContexts';
import Select from 'react-select'
import { saveVendorRate } from '../../utils/rates';
import { handleCustomListChange } from '../../utils/globalHandlers';

export default function ContractualDefinition() {

    const toggler = UseToggleContext();
    const context = UseContextClues();

    const inputStyles = "border px-2 py-1 rounded font-normal"
    const labelStyles = "font-medium flex flex-col rounded mb-3 w-full "

    const checkboxStyles = "mr-2 "

    const labelStyle = "font-medium mt-3 ";
    const inputStyle = "font-normal w-full border py-2 px-4 mt-2 mb-2 rounded-lg";

    const colStyles = "w-[267px] h-10 border"
    const rowStyles = "h-10 border"

    const [ settings, setSettings ] = useState({
        "brand_definition": "mnoBt",
        "otc_definition": "medispanOpLessDiabeticSupplies",
        "exclusions": {
            "exclusionLdd": false,
            "exclusionAuthGenerics": false,
            "exclusionVaccines": true,
            "exclusionVa": true,
            "exclusionItu": true,
            "exclusionLtc": true,
            "exclusionHomeInfusion": true,
            "exclusionOTC": true,
            "exclusion340b": true,
            "exclusionCosmetic": false,
            "exclusionRepack": false,
            "exclusionInstitutional": false,
            "exclusionMCO": false,
            "exclusionClinic": false,
            "exclusionNuclear": false,
            "exclusionMilitary": false,
            "exclusionCovidDrugs": false,
            "exclusionDAW3456": false,
            "exclusionCompounds": false,
            "exclusionVitamins": false,
            "exclusionNonFdaDrugs": false,
            "exclusionMSB": false
        },
        "rate": 0,
        "channel": "",
        "start_date": "2024-06-11",
        "end_date": "2024-06-11",
        "vendor_name": "",
        "formulary_name": "",
        "lob_name": "",
        "custom_lists": {
            "exclusion": [],
            "constraint": []
        }
    });

    const [ defaultList, setDefaults ] = useState({
        exclusions : {},
        constraint : {},
    });

    useEffect(() => {
        var constraints = context.exclusionSettings.custom_lists?.constraint.map(ele => ({label: ele, value: ele}))

        if (constraints)setDefaults(data => ({...data, constraint: constraints}))

    }, [context.exclusionSettings.custom_lists.constraint ])
    useEffect(() => {
        var exclusions = context.exclusionSettings.custom_lists?.exclusion.map(ele => ({label: ele, value: ele}))

        if (exclusions)setDefaults(data => ({...data, exclusions: exclusions}))
    }, [context.exclusionSettings.custom_lists.exclusion ])

    function handleChange(e){
        const { name, value, checked } = e.target;
        if(name.includes("exclusion")) {
            setSettings(data => ({
                ...data,
                exclusions: {
                    ...data.exclusions,
                    [name]: checked
                }
            }));
        } else {
            setSettings(data => ({
                ...data,
                [name]: value,
            }));
           
        };
    };

    function saveRate(e) {
        e.preventDefault();
        saveVendorRate(settings);
    }

    return (
        <div className='bg-background-light'>
            <div style={{ height: toggler.pageHeight-100  }} className='w-[1200px] mx-auto bg-white overflow-y-auto'>
                <div className='flex justify-center '>
                    <form onSubmit={saveRate} className='capitalize  pt-20 '>
                        <div className='flex gap-10'>
                            <div> 
                                <div className='flex gap-4'>
                                    <label htmlFor="vendor_name" className={labelStyles}>vendor Name
                                        <input value={settings.vendor_name} name="vendor_name" onChange={handleChange} type='text' className={inputStyles} />
                                    </label>
                                    <label htmlFor='formulary_name' className={labelStyles}>formulary name
                                        <input value={settings.formulary_name} name='formulary_name' onChange={handleChange} type='text' className={inputStyles} />
                                    </label>
                                </div>
                                <div className='flex gap-4'>
                                    <label htmlFor='lob_name' className={labelStyles}>line of business name
                                        <input value={settings.lob_name} name='lob_name' onChange={handleChange} type='text' className={inputStyles} />
                                    </label>
                                    <label htmlFor='rate' className={labelStyles}>Rate
                                        <input value={settings.rate} name='rate' onChange={handleChange} type='number' className={inputStyles} />
                                    </label>
                                </div>
                                <label htmlFor='channel' className={labelStyles}>Channel
                                    <input value={settings.channel} name='channel' onChange={handleChange} type='text' className={inputStyles} />
                                </label>
                                <div className='flex gap-4'>
                                    <label htmlFor='start_date' className={'flex flex-col border  p-3 w-full rounded'}>Start date
                                        <input value={settings.start_date} name='start_date' onChange={handleChange} type='date' className={''} />
                                    </label>
                                    <label htmlFor='end_date' className={'flex flex-col border  p-3 w-full rounded'}>End date
                                        <input value={settings.end_date} name='end_date' onChange={handleChange} type='date' className={''} />
                                    </label>
                                </div>
                                <div className='flex gap-4'>
                                    <label className={labelStyle} htmlFor="brand_definition">Brand Definition:
                                        <select className={inputStyle} id="brand_definition" value={settings.brand_definition} name="brand_definition" onChange={handleChange}>
                                            <option value="mnoBt">MNO/BT</option>
                                            <option value="mno">MNO</option>
                                        </select>
                                    </label>
                                    
                                    <label className={labelStyle} htmlFor="otc_definition">OTC Definition:
                                        <select className={inputStyle} value={settings.otc_definition} id="otc_definition" name="otc_definition" onChange={handleChange}>
                                            <option value="medispanOpLessDiabeticSupplies">Medispan OP less diabetic supplies</option>
                                            <option value="medispanOpLessTestStrips">Medispan OP less test strips</option>
                                            <option value="medispanOp">Medispan OP</option>
                                        </select>
                                    </label>
                                </div>
                                <label className={labelStyles}>custom list exclusions
                                    <Select 
                                        isMulti
                                        options={context.models.customList.map((value, idx) => ({
                                            value: `${value.list_name}`,
                                            label: `${idx+1}: ${value.list_name}`,
                                            toString: () => value.id
                                        }))}
                                        onChange={e => handleCustomListChange(e, "exclusion", setSettings)}
                                        name='exclusion'
                                        menuShouldScrollIntoView={false}
                                        className='mb-2 mt-1'
                                        menuPosition='fixed'
                                        menuPortalTarget={document.body} 
                                        styles={{ menuPortal: base => ({ ...base, zIndex: 500, fontSize: "x-small"  }) }}
                                    />
                                </label>
                                <label className={labelStyles}>custom list constraints
                                    <Select 
                                        isMulti
                                        options={context.models.customList.map((value, idx) => ({
                                            value: `${value.list_name}`,
                                            label: `${idx+1}: ${value.list_name}`,
                                            toString: () => value.id
                                        }))}
                                        onChange={e => handleCustomListChange(e, 'constraint', setSettings)}
                                        menuShouldScrollIntoView={false}
                                        name='constraint'
                                        className='mt-1'
                                        menuPosition='fixed'
                                        menuPortalTarget={document.body} 
                                        styles={{ menuPortal: base => ({ ...base, zIndex: 500, fontSize: "x-small", }) }}
                                    />
                                </label>
                            </div>

                            <div className='flex flex-col gap-x-6 mb-2  '>         
                                <div>
                                    <input className={checkboxStyles} type="checkbox" id="exclusionLdd" name="exclusionLdd"  checked={settings.exclusions.exclusionLdd} onChange={handleChange}/>
                                    <label htmlFor="exclusionLdd">LDD</label>
                                </div>
                                <div>
                                    <input className={checkboxStyles} type="checkbox" id="exclusionVaccines" name="exclusionVaccines" checked={settings.exclusions.exclusionVaccines} onChange={handleChange}/>
                                    <label htmlFor="exclusionVaccines">Vaccines</label>
                                </div>
                                <div className='whitespace-nowrap'>
                                    <input className={checkboxStyles} type="checkbox" id="exclusionAuthGenerics" name="exclusionAuthGenerics" checked={settings.exclusions.exclusionAuthGenerics}  onChange={handleChange}/>
                                    <label className='' htmlFor="exclusionAuthGenerics">Authorized Generics</label>
                                </div>
                                <div>
                                    <input className={checkboxStyles} type="checkbox" id="exclusionVa" name="exclusionVa" checked={settings.exclusions.exclusionVa} onChange={handleChange}/>
                                    <label htmlFor="exclusionVa">VA</label>
                                </div>
                                <div>
                                    <input className={checkboxStyles} type="checkbox" id="exclusionItu" name="exclusionItu" checked={settings.exclusions.exclusionItu} onChange={handleChange}/>
                                    <label htmlFor="exclusionItu">ITU</label>
                                </div>
                                <div>
                                    <input className={checkboxStyles} type="checkbox" id="exclusionLtc" name="exclusionLtc" checked={settings.exclusions.exclusionLtc} onChange={handleChange}/>
                                    <label htmlFor="exclusionLtc">LTC</label>
                                </div>
                                <div>
                                    <input className={checkboxStyles} type="checkbox" id="exclusionHomeInfusion" name="exclusionHomeInfusion" checked={settings.exclusions.exclusionHomeInfusion} onChange={handleChange}/>
                                    <label htmlFor="exclusionHomeInfusion">Home Infusion</label>
                                </div>
                                <div>
                                    <input className={checkboxStyles} type="checkbox" id="exclusionOTC" name="exclusionOTC" checked={settings.exclusions.exclusionOTC} onChange={handleChange}/>
                                    <label htmlFor="exclusionOTC">OTC</label>
                                </div>
                                <div>
                                    <input className={checkboxStyles} type="checkbox" id="exclusion340b" name="exclusion340b" checked={settings.exclusions.exclusion340b} onChange={handleChange}/>
                                    <label htmlFor="exclusion340b">340B</label>
                                </div>
                                <div>
                                    <input className={checkboxStyles} type="checkbox" id="exclusionCosmetic" name="exclusionCosmetic" checked={settings.exclusions.exclusionCosmetic} onChange={handleChange}/>
                                    <label htmlFor="exclusionCosmetic">Cosmetic</label>
                                </div>
                                <div>
                                    <input className={checkboxStyles} type="checkbox" id="exclusionRepack" name="exclusionRepack" checked={settings.exclusions.exclusionRepack} onChange={handleChange}/>
                                    <label htmlFor="exclusionRepack">Repackager</label>
                                </div>
                                <div>
                                    <input className={checkboxStyles} type="checkbox" id="exclusionInstitutional" name="exclusionInstitutional" checked={settings.exclusions.exclusionInstitutional} onChange={handleChange}/>
                                    <label htmlFor="exclusionInstitutional">Institutional</label>
                                </div>
                                <div>
                                    <input className={checkboxStyles} type="checkbox" id="exclusionMCO" name="exclusionMCO" checked={settings.exclusions.exclusionMCO} onChange={handleChange}/>
                                    <label htmlFor="exclusionMCO">MCO</label>
                                </div>
                                <div>
                                    <input className={checkboxStyles} type="checkbox" id="exclusionClinic" name="exclusionClinic" checked={settings.exclusions.exclusionClinic} onChange={handleChange}/>
                                    <label htmlFor="exclusionClinic">Clinic</label>
                                </div>
                                <div>
                                    <input className={checkboxStyles} type="checkbox" id="exclusionNuclear" name="exclusionNuclear" checked={settings.exclusions.exclusionNuclear} onChange={handleChange}/>
                                    <label htmlFor="exclusionNuclear">Nuclear</label>
                                </div>
                                <div>
                                    <input className={checkboxStyles} type="checkbox" id="exclusionMilitary" name="exclusionMilitary" checked={settings.exclusions.exclusionMilitary} onChange={handleChange}/>
                                    <label htmlFor="exclusionMilitary">Military</label>
                                </div>
                                <div>
                                    <input className={checkboxStyles} type="checkbox" id="exclusionCovidDrugs" name="exclusionCovidDrugs" checked={settings.exclusions.exclusionCovidDrugs} onChange={handleChange}/>
                                    <label htmlFor="exclusionCovidDrugs">Covid Drugs</label>
                                </div>
                                <div>
                                    <input className={checkboxStyles} type="checkbox" id="exclusionDAW3456" name="exclusionDAW3456" checked={settings.exclusions.exclusionDAW3456} onChange={handleChange}/>
                                    <label htmlFor="exclusionDAW3456">DAW 3456</label>
                                </div>
                                <div>
                                    <input className={checkboxStyles} type="checkbox" id="exclusionCompounds" name="exclusionCompounds" checked={settings.exclusions.exclusionCompounds} onChange={handleChange}/>
                                    <label htmlFor="exclusionCompounds">Compounds</label>
                                </div>
                                <div>
                                    <input className={checkboxStyles} type="checkbox" id="exclusionVitamins" name="exclusionVitamins" checked={settings.exclusions.exclusionVitamins} onChange={handleChange}/>
                                    <label htmlFor="exclusionVitamins">Vitamins</label>
                                </div>
                                <div>
                                    <input className={checkboxStyles} type="checkbox" id="exclusionNonFdaDrugs" name="exclusionNonFdaDrugs" checked={settings.exclusions.exclusionNonFdaDrugs} onChange={handleChange}/>
                                    <label htmlFor="exclusionNonFdaDrugs">Non FDA Drugs</label>
                                </div>
                                <div>
                                    <input className={checkboxStyles} type="checkbox" id="exclusionMSB" name="exclusionMSB" checked={settings.exclusions.exclusionMSB} onChange={handleChange}/>
                                    <label htmlFor="exclusionMSB">Multisource Brands - O</label>
                                </div>
                            </div>
                        </div>
                        <div className='flex justify-between'>
                            <button className='border py-3 px-8 rounded-xl bg-light-blue text-white font-bold hover:bg-hover-blue' type='submit'>Save vendor rates</button>
                            {/* <button className='border py-3 px-8 rounded-xl bg-light-blue text-white font-bold hover:bg-hover-blue' type='submit'>Save vendor rates</button> */}
                        </div>
                    </form>
                    
                </div>
                    {/* <table>
                        <thead>
                            <tr>
                                <th className={colStyles}></th>
                                <th className={colStyles}>Start Date</th>
                                <th className={colStyles}>End Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Contract Year 1</td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Contract Year 2</td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table> */}
            </div>
        </div>
    );
};