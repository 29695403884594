import React, { useContext, useEffect, useState } from 'react';
import PopUpSection from '../Section/PopUpSection';
import { globalBoolHandler } from '../../utils/globalHandlers';
import PipelineForm from '../Forms/PipelineForm';
import { listPipelineEvents, createPipelineEvent } from '../../utils/pipeline';
import PipelineList from '../Tables/PipelineList';
import PipelineCategory from '../Tables/PipelineCategory';
import { UseToggleContext } from '../../context/ToggleContexts';

export default function Pipeline() {

    const toggler = UseToggleContext();

    const [ payload, load ] = useState({
        create: {
            category:"",
            drugname:"",
            entry_mkt_share: NaN,
            startdate:"2020-02-02",
            wac_per_unit:NaN,
            unit_per_day:NaN,
            day_per_rx:NaN,
            specialty_ind:"y"
        },
        list : [],
    });

    const [ pipeline, setPipe ] = useState([]);

    function handleSubmit(e) {
        e.preventDefault();
        createPipelineEvent(payload.create, load, payload);
    };

    useEffect(() => {
        listPipelineEvents(payload, load);
    }, []);

    return (
        <>
            <PopUpSection popone={"absolute top-0 z-[200] h-[100vh] w-[100vw] bg-gray-500 opacity-[0.6]"} 
                poptwo={`absolute left-[50%] translate-x-[-50%] ${toggler.pageHeight<800?'top-[2%] bottom-0 translate-y-[-1%]':"top-[50%] translate-y-[-50%]"} z-[200] w-full overflow-y-auto`}
            >
                <form onSubmit={handleSubmit} className=' bg-primary-dark rounded-xl max-w-[1440px] h-[800px] flex mx-auto justify-center flex-col px-8 overflow-y-auto'>
                    <div className='text-white mb-2 tracking-widest uppercase grid grid-cols-[1fr,3fr,2fr] text-xs gap-5' >
                        <div>
                            Add a new pipeline drug
                        </div>
                        <div>
                            Pipeline Events
                        </div>
                        <div>
                            Reference Category Stats - <span className='lowercase'><span className='capitalize'>Incumbent</span> Products Based on Current Case utilization</span>
                        </div>
                    </div>
                    <div className='grid grid-cols-[1fr,3fr,2fr] gap-5'>
                        <PipelineForm {...{ load, payload, setPipe }} />
                        <div className='bg-white rounded overflow-y-auto h-[650px] px-1 '>
                            {payload.list.length>0?<PipelineList {...{ payload: payload?.list }} />: <div className='flex h-full items-center justify-center'><l-leapfrog color="black"></l-leapfrog></div>}
                        </div>
                        <div className='bg-white rounded h-[650px] overflow-y-auto'>
                            {pipeline.length>0?<PipelineCategory {...{ payload: pipeline }} />:<div className='h-full text-center flex justify-center items-center font-medium tracking-widest uppercase'>Choose Category</div>}
                        </div>
                    </div>
                    <div>
                        <button className='text-white border rounded-lg  mt-4 py-2 w-40 bg-light-blue hover:bg-inherit' onClick={() => globalBoolHandler("pipelineEvents", toggler.toggle, toggler.setToggle, false)}>
                            Cancel
                        </button>
                        <button className='text-white border rounded-lg border-white mt-4 py-2 w-40  hover:bg-light-blue float-right'>
                            Save
                        </button>
                    </div>
                </form>
            </PopUpSection>
        </>
    );
};