import React from 'react';
import { formatDecimalToPercentage, formatPerBrandEstimates } from '../../utils/resultsSummary';

export default function RebtatableClaims({useNewData}) {

    const rowStyles = "border py-1 pl-2"

    return (
        <>
            <table className='w-full ' >
                <thead>
                    <tr>
                        <th className='text-left pl-2 pb-2'>
                            Year 1 Macro Performance KPI
                        </th>
                        <th>

                        </th>
                    </tr>
                </thead>
                <tbody className=' align-top'>
                    <tr>
                        <td className={rowStyles}>
                            Per Rebatable Claim by Channel:
                        </td>
                        <td className={rowStyles+" pl-0 "}>
                            <table className='w-full'>
                                <thead>
                                    <tr>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.entries(useNewData.macro).map((rebateableStats, idx) => (
                                        (rebateableStats[0] ==="R30"||rebateableStats[0] === "R90"|| rebateableStats[0] ==="S"|| rebateableStats[0] ==="M")&&<tr key={`${idx}: ${rebateableStats[0]} - ${rebateableStats[1].rebates_per_rebated_claim} `} className='border-b last:border-none '>
                                            <td className={'pl-2'}>
                                                {rebateableStats[0]}
                                            </td>
                                            <td className={'text-right pr-2'}>
                                                {formatPerBrandEstimates(rebateableStats[1].rebates_per_rebated_claim)}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td className={rowStyles}>
                            Biosimilar Penetration:
                        </td>
                        <td className={rowStyles+" pl-0 "}>
                            <table className='w-full'>
                                <thead>
                                    <tr>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.entries(useNewData.macro.biosimilar_penetration).map((rebateableStats, idx) => (
                                        <tr key={`${idx}: ${rebateableStats[0]} - ${rebateableStats[1].rebates_per_rebated_claim} `} className='border-b last:border-none '>
                                            <td className={'pl-2'}>
                                                {rebateableStats[0]}
                                            </td>
                                            <td className={'text-right pr-2'}>
                                                {formatDecimalToPercentage(rebateableStats[1])}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td className={rowStyles}>
                            Brand Formulary Compliance:
                        </td>
                        <td className={rowStyles+" text-right pr-2"}>
                            {formatDecimalToPercentage(useNewData.macro.brand_formulary_compliance.compliance_rate)}
                        </td>
                    </tr>
                    <tr>
                        <td className={rowStyles}>
                            Rebated Brand % (by rx):
                        </td>
                        <td className={rowStyles+" text-right pr-2"}>
                            {formatDecimalToPercentage(useNewData.macro.rebated_brand_pct.rebated_brand_pct)}
                        </td>
                    </tr>
                </tbody>
            </table>
        </>
    )
}