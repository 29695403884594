import React, { createContext, useContext, useState } from 'react';
import { globalBoolHandler } from '../utils/globalHandlers';
import { loadModelSettings } from '../utils/claimsLoad';
import { listCustomList } from '../utils/customLists';
import { fetchAndLoadFormulary } from '../utils/formularySelection';

const UserContext = createContext({});

export default function UserContextProvider({children}) {
    
    // const onIdle = () => {
    //     updateState('Idle')
    //     globalBoolHandler("timeOut", toggle, setToggle, false);
    // };

    // const onActive = () => {
    //     updateState('Active')
    //     globalBoolHandler("timeOut", toggle, setToggle, false);
    // };

    // const onAction = () => {
    //     setCounter(actionCounter + 1);
    // };

    // const onPrompt = () => {
    //     updateState('Warning')
    //     globalBoolHandler("timeOut", toggle, setToggle, true);
    // };

    // const { getRemainingTime, activate } = useIdleTimer({
    //     onIdle,
    //     onAction,
    //     onActive,
    //     onPrompt,
    //     promptBeforeIdle: 300_000,
    //     timeout:  900_000,
    //     throttle: 500
    // });

    // useEffect(() => {
    //     // if (user.userData.username) {
    //       loadModelSettings(setModels);
    //     // }
    //   }, [casenumber, user.userData.username])
    //   useEffect(() => {
    //     // if (user.userData.username) {
    //       listCustomList(setModels);
    //     // }
    //   }, [casenumber, user.userData.username])
    
    // useEffect(() => {
    //     // if (isLoading&&user.loggedIn) {
    //       fetchAndLoadFormulary(addFormularies);
    //     // };
    //   }, [user.loggedIn]);
    // const [ userActivity, updateState ] = useState('active');

    // const [ userActivity, updateState ] = useState('active');

    const [ user, setUser ] = useState({
        loggedIn: false,
        token: null,
        userData: {
            username: '',
            user_type: ''
        }
    });

    const userProps = {
        setUser,
        user,
        // userActivity,
        // updateState
    };
    
    return <UserContext.Provider value={userProps}>{children}</UserContext.Provider>
};

export const UseUserContext = () => useContext(UserContext);