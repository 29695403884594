import React, { useContext, useState } from 'react';
import CollapsibleButton from '../Buttons/CollapsibleButton';
import { UseContextClues } from '../../context/ContextClues';
import { UseToggleContext } from '../../context/ToggleContexts';
import { UseUserContext } from '../../context/UserContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faCheck, faCircleQuestion, faGears, faCompress } from "@fortawesome/free-solid-svg-icons";
import { handleAuxHelp, handleHelp } from '../../utils/globalHandlers';

export default function UserGuide() {

    const account = UseUserContext();
    const context = UseContextClues();
    const toggler = UseToggleContext();

    const [ hovered, setHovered ] = useState(false)

    const [ whichButton, setButton ] = useState({
        stage1: false,
        stage2: false,
        stage3: false,
        stage4: false,
        stage5: false,
        stage6: false,
        stage7: false,
        stage8: false
    });

    function reset() {
        setButton({
            stage1: false,
            stage2: false,
            stage3: false,
            stage4: false,
            stage5: false,
            stage6: false,
            stage7: false,
            stage8: false
        })
    } 

    const animationSequence = ' transition-[max-height] duration-700'

    function handleHidden(e) {
        const { name, value } = e.target;
        setButton({
            ...whichButton,
            [name]: !whichButton[name]
        })
    };

    function claims () {
        return (
            !context.settings.claims.tName ||
            !context.settings.claims.startDate ||
            !context.settings.claims.endDate ||
            !context.mainData
        )
    } 
    function formularies () {
        return (
            !context.settings.formulary.baseFormulary ||
            !context.settings.formulary.targetFormulary 
        )
    } 

    function results() {
        return (
            !context.results ||
            !context.target
        )
    }

    const [ delayHandler, setDelayHandler] = useState(null);

    function handleMouseEnter(){
        setDelayHandler(setTimeout(() => {
            setHovered(true)
        }, 1000))
    };
    function handleMouseLeave(){
        setHovered(false)
        clearTimeout(delayHandler)
    };

    return (
        <>
            {toggler.toggle?.helpWindow&&<div style={{ height: (toggler.pageHeight-100)*.4+"px"}} className=' border  '>  
                <div className=' m-4 overflow-y-auto '>  
                        <div className='grid relative' style={{ height: ((toggler.pageHeight)*.4)-75+"px"}}>
                        <div className={`absolute top-[20px] left-[10px] flex flex-col justify-center ${hovered ? "block" : "hidden"}`}>
                            <div className="bg-primary-dark text-white p-4 rounded-lg font-xs shadow-xl">
                                <div>Collapse all </div>
                            </div>
                            
                        </div>
                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        <button onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={reset} className='float-left' aria-label='Collapse all'>
                                            <FontAwesomeIcon className="text-primary-blue" icon={faCompress}  />
                                        </button>
                                        Step
                                    </th>
                                    <th>
                                        Status
                                    </th>
                                    <th>
                                        Help
                                    </th>
                                </tr>
                            </thead>
                            <tbody>

                                <tr>
                                    <td className='border px-4'><button name='stage1' type='button' onClick={handleHidden}>Stage 1: Choose Claims dataset to model</button>
                                        <div className={(whichButton.stage1 ? 'max-h-[400px]' : 'max-h-0 overflow-hidden opacity-0 pointer-events-none ') +  animationSequence}>
                                            <div className='pl-12 flex flex-row'><div className='mr-2'>a.</div> Choose from a drop down of your pre-loaded tables.</div>
                                            <div className='pl-12 flex flex-row'><div className='mr-2'>b.</div> Import a new data table from a .csv file.</div>
                                            <div className='pl-12 flex flex-row'><div className='mr-2'>c.</div> Select your channel designation – how do you want the output to be categorized?</div>
                                            <div className='pl-12 flex flex-row'><div className='mr-2'>d.</div> Select the timeframe of your dataset. This will help the model trend utilization in the outer years.</div>
                                        </div>
                                    </td>
                                    <td className='border text-center px-8'> 
                                        {
                                            claims() ? 
                                            <FontAwesomeIcon className="text-primary-red" icon={faCircleXmark} />
                                            :
                                            <FontAwesomeIcon className="text-primary-green" icon={faCheck} />

                                        }
                                    </td>
                                    <td className='border text-center px-8'>
                                        <button onClick={() => handleHelp('claimsControl', toggler)}>
                                            <FontAwesomeIcon className="text-primary-blue" icon={faCircleQuestion} />
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='border px-4 '><button name='stage2' type='button' onClick={handleHidden}>Stage 2: Choose Formularies to model</button>
                                        <div className={(whichButton.stage2 ? 'max-h-[600px]' : 'max-h-0 overflow-hidden opacity-0 pointer-events-none ') +  animationSequence}>
                                            <div className='pl-12 flex flex-row'><div className='mr-2'>a.</div> Choose Base Formulary. This will be your base scenario, with no market share shift assumptions.</div>
                                            <div className='pl-12 flex flex-row'><div className='mr-2'>b.</div> Choose Target Formulary. This will be your target scenario, where a market share shift will be applied FROM the targeted products TO the preferred targets within a given therapeutic category.</div>
                                            <div className='pl-12 flex flex-row'><div className='mr-2'>c.</div> If you do not see your formulary here, you may select the Rates tab to configure your custom formulary (step 5)</div>
                                        </div>
                                    </td>
                                    <td className='border text-center px-8'> 
                                        {
                                            (claims(), formularies()) ? 
                                            <FontAwesomeIcon className="text-primary-red" icon={faCircleXmark} />
                                            :
                                            <FontAwesomeIcon className="text-primary-green" icon={faCheck} />

                                        }
                                    </td>
                                    <td className='border text-center px-8'>
                                        <button onClick={() => handleHelp('formularySelect', toggler)}>
                                            <FontAwesomeIcon className="text-primary-blue" icon={faCircleQuestion} />
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='border px-4 '><button name='stage3' type='button' onClick={handleHidden}>Stage 3: Review Assumptions (Optional)</button>
                                        <div className={(whichButton.stage3 ? 'max-h-40' : 'max-h-0 overflow-hidden opacity-0 pointer-events-none ') +  animationSequence}>
                                        <div className='pl-12 flex flex-row'><div className='mr-2'>a.</div> Review the Assumptions tab. Make sure inflation and contracting improvement are in line with your supply chain expectations. Contracting improvement is applied relative to the overall rebate earning potential.</div>
                                        </div>
                                    </td>
                                    <td className='border text-center px-8'> 
                                        <FontAwesomeIcon className="text-gray-500" icon={faGears} />
                                    </td>
                                    <td className='border text-center px-8'>
                                        <button onClick={() => handleHelp('assumptions', toggler)}>
                                            <FontAwesomeIcon className="text-primary-blue" icon={faCircleQuestion} />
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='border px-4 '><button name='stage4' type='button' onClick={handleHidden}>Stage 4: Review Contractual Definitions (Optional)</button>
                                        <div className={(whichButton.stage4 ? 'max-h-40' : 'max-h-0 overflow-hidden opacity-0 pointer-events-none ') +  animationSequence}>
                                            <div className='pl-12 flex flex-row'><div className='mr-2'>a.</div> Review the brand definition and claim exclusions. Make sure these are in line with the intent of your contractual position.</div>
                                        </div>
                                    </td>
                                    <td className='border text-center px-8'> 
                                        {
                                            // !context.settings.claims.tName ||
                                            // !context.settings.claims.startDate ||
                                            // !context.settings.claims.endDate ? 
                                            <FontAwesomeIcon className="text-gray-500" icon={faGears} />
                                            // :
                                            // <FontAwesomeIcon className="text-primary-green" icon={faCheck} />

                                        }
                                    </td>
                                    <td className='border text-center px-8'>
                                        <button onClick={() => handleHelp('contractDefintions', toggler)}>
                                            <FontAwesomeIcon className="text-primary-blue" icon={faCircleQuestion} />
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='border px-4 '><button name='stage5' type='button' onClick={handleHidden}>Stage 5: Review Rates (Advanced Optional)</button>
                                        <div className={(whichButton.stage5 ? 'max-h-[800px]' : 'max-h-0 overflow-hidden opacity-0 pointer-events-none ') +  animationSequence}>
                                            <div className='pl-12 flex flex-row'><div className='mr-2'>a.</div> Review your drug level information for base rates, and targeted/preferred/neutral status.</div>
                                            <div className='pl-20 flex flex-row'> <div className='mr-2'>i.</div> Target – These products will have utilization shifted OUT. Shift is performed on days supply and all utilization is adjusted on a pro rata basis.</div>
                                            <div className='pl-20 flex flex-row'> <div className='mr-2'>ii.</div> Preferred – These products will have utilization shifted IN. Shift is performed on days supply and all utilization is adjusted on a pro rate basis. The shift will be distributed on a weighted basis calculated from current market share.</div>
                                            <div className='pl-20 flex flex-row'> <div className='mr-2'>iii.</div> Neutral – These products will not have market share shift IN or OUT.</div>
                                        </div>
                                    </td>
                                    <td className='border text-center px-8'> 
                                        <FontAwesomeIcon className="text-gray-500" icon={faGears} />
                                    </td>
                                    <td className='border text-center px-8'>
                                        <button onClick={() => handleAuxHelp('rates', toggler)} className='disabled:cursor-not-allowed' disabled={!account.user.userData.user_type==="admin"}>
                                            <FontAwesomeIcon className="text-primary-blue" icon={faCircleQuestion} />
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='border px-4 '><button name='stage6' type='button' onClick={handleHidden}>Stage 6: Review Events (Advanced Optional)</button>
                                        <div className={(whichButton.stage6 ? 'max-h-[600px]' : 'max-h-0 overflow-hidden opacity-0 pointer-events-none ') +  animationSequence}>
                                            <div className='pl-12 flex flex-row'><div className='mr-2'>a.</div> Review marketplace events and their impact on your modeling. Events are designed at either the Brand/Generic, Category, or Drug level. Events can modify WAC price, rebate rate, or total rx volume. Your events will be saved for future use. If you do not want a certain event applied, you may deselect the “Apply?” checkbox. The following are examples of events:</div>
                                            <div className='pl-20 flex flex-row'> <div className='mr-2'>i.</div> Patent Expirations</div>
                                            <div className='pl-20 flex flex-row'> <div className='mr-2'>ii.</div> Known rebate contract improvements</div>
                                            <div className='pl-20 flex flex-row'> <div className='mr-2'>iii.</div> Utilization increases (e.g. Weight Loss)</div>
                                        </div>
                                    </td>
                                    <td className='border text-center px-8'> 
                                        <FontAwesomeIcon className="text-gray-500" icon={faGears} />
                                    </td>
                                    <td className='border text-center px-8'>
                                        <button onClick={() => handleAuxHelp('events', toggler)} className='disabled:cursor-not-allowed' disabled={!account.user.userData.user_type==="admin"}>
                                            <FontAwesomeIcon className="text-primary-blue" icon={faCircleQuestion} />
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='border px-4 '><button name='stage7' type='button' onClick={handleHidden}>Stage 7: Results</button>
                                        <div className={(whichButton.stage7 ? 'max-h-40' : 'max-h-0 overflow-hidden opacity-0 pointer-events-none ') +  animationSequence}>
                                            <div className='pl-12 flex flex-row'><div className='mr-2'>a.</div> Once you have completed steps 1-4, you are ready to run your model. After selecting “Run Models”, you will see the results load momentarily. Download to excel or copy to clipboard.</div>
                                        </div>
                                    </td>
                                    <td className='border text-center px-8'> 
                                        {
                                            results() ? 
                                            <FontAwesomeIcon className="text-primary-red" icon={faCircleXmark} />
                                            :
                                            <FontAwesomeIcon className="text-primary-green" icon={faCheck} />

                                        }
                                    </td>
                                    <td className='border text-center px-8'>
                                        <button onClick={() => handleAuxHelp('results', toggler)}>
                                            <FontAwesomeIcon className="text-primary-blue" icon={faCircleQuestion} />
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='border px-4 '><button name='stage8' type='button' onClick={handleHidden}>Stage 8: Crystal Ball / Analytics & Drivers</button>
                                        <div className={(whichButton.stage8 ? 'max-h-40' : 'max-h-0 overflow-hidden opacity-0 pointer-events-none ') +  animationSequence}>
                                            <div className='pl-12 flex flex-row'><div className='mr-2'>a.</div> After running results, you can access the Analytics & Drivers section, or the Crystal Ball section. Crystal Ball will provide category level detail based on your model selection.</div>
                                        </div>
                                    </td>
                                    <td className='border text-center px-8'> 
                                        <FontAwesomeIcon className="text-gray-500" icon={faGears} />
                                    </td>
                                    <td className='border text-center px-8'>
                                        <button onClick={() => handleHelp('crystalBall', toggler)}>
                                            <FontAwesomeIcon className="text-primary-blue" icon={faCircleQuestion} />
                                        </button>
                                    </td>
                                </tr>
                                
                            </tbody>

                        </table>
                    </div>
                </div>
            </div>}
        </>
    )
}