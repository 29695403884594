import React, { useContext,  useState } from 'react';
import { UseContextClues } from '../../context/ContextClues';
import { deltaArray, divideDeltaArray, sumTotalObjects, transform } from '../../utils/pricingLens';
import { globalUpdateKeyValue } from '../../utils/globalHandlers';
import { convertYears, formatCurrencyExp, sumTotals } from '../../utils/resultsSummary';
import LockedMessage from '../Error/LockedMessage';

export default function PerformanceLens({reference}) {

    const context = UseContextClues();

    const [ scenario, selectScene ] = useState("results")
    const [ performance, change ] = useState({
        "Year1":{"R30":0,"R90":0,"M":0,"S":0},
        "Year2":{"R30":0,"R90":0,"M":0,"S":0},
        "Year3":{"R30":0,"R90":0,"M":0,"S":0},
        "Year4":{"R30":0,"R90":0,"M":0,"S":0},
        "Year5":{"R30":0,"R90":0,"M":0,"S":0}
    });
    // const [ yeaRange, range ] = useState(1)
    
    const totals = JSON.parse(context[scenario]?.Rebates_summary);
    const claimCount = JSON.parse(context[scenario]?.Rx_summary);
    const gtee = sumTotals(transform(sumTotalObjects(claimCount, performance)));
    const delta = deltaArray(gtee, sumTotals(transform(totals)));

    const headerStyles = "font-bold pr-8 bg-primary-dark text-white border pl-2"
    const bodyStyles = " py-3 text-right px-2 ";
    
    return (
        <div ref={reference}>
            <div className='pb-8 flex items-center gap-8'>
                <select className='uppercase font-medium border py-1 px-2' onChange={(e) => selectScene(e.target.value)}>
                    <option value="results">standard</option>
                    <option value="target">target</option>
                </select>
                {/* <div>
                    <label className='font-medium'>Length of Deal in Years
                        <input type='range' className='ml-4' min={1} max={5} value={yeaRange} onChange={e => range(e.target.value)} />
                    </label>
                </div> */}
            </div>
            <LockedMessage />
            <table>
                <thead>
                    <tr className='bg-primary-dark text-white'>
                        <th className='text-left py-2 pl-2'>Channel</th>
                        {Object.keys(JSON.parse(context[scenario].Rebates_summary)).map((years, idx) => (
                            context.exclusionSettings.dealLength>=idx+1&&<th key={`${idx} - Channel Year: ${years}`} className='border whitespace-pre-wrap h-12 w-[160px] py-1 px-2 text-sm bg-primary-dark text-white  my-2 text-center' >
                                {convertYears(years, context.settings.year.year1)}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody className='bg-background-light'>
                    {Object.keys(transform(performance)).map((channel, idx) => (
                        <tr key={`Performance Lens Numbers Table No.: ${idx}`}>
                            <td className={headerStyles}>
                                {channel}
                            </td>
                            {Object.entries(transform(performance)[channel]).map((year, index) => (
                                context.exclusionSettings.dealLength>=index+1&&<td key={`${index} performance lens input by year: ${year}`} className=''>
                                    <span className='pl-2'>
                                        $
                                    </span>
                                    <input 
                                        className='px-2 p-1 my-1 border-4 w-[120px]  mt-2'
                                        type='number' 
                                        min={0} 
                                        step={1} 
                                        autoFocus
                                        value={performance[year[0]][channel]}
                                        onChange={e => globalUpdateKeyValue(channel, e.target.value, change, year[0])} 
                                    />
                                </td>
                            ))}
                        </tr>
                    ))}
                    <tr>
                        <td className={headerStyles+" pt-20"}>Gtee</td>
                        {gtee.map((totals, idx) => (
                            context.exclusionSettings.dealLength>=idx+1&&<td key={`${idx}: Performance Lens - gtee`} className={bodyStyles +" pt-20"}>
                                {formatCurrencyExp(totals)}
                            </td>
                        ))}
                    </tr>
                    <tr>
                        <td className={headerStyles}>Performance</td>
                        {sumTotals(transform(totals)).map((total, idx) => (
                            context.exclusionSettings.dealLength>=idx+1&&<td key={`${idx}: Performance Lens - performance`} className={bodyStyles}>
                                {formatCurrencyExp(total)}
                            </td>
                        ))}
                    </tr>
                    <tr>
                        <td className={headerStyles}>Delta</td>
                        {delta.map((delta, idx) => (
                            context.exclusionSettings.dealLength>=idx+1&&<td key={`${idx}: Performance Lens - delta`} className={bodyStyles+(delta<0?"text-primary-red":"text-primary-green")}>
                                {formatCurrencyExp(delta)}
                            </td>
                        ))}
                    </tr>
                    <tr>
                        <td className={headerStyles}>Percentage</td>
                        {divideDeltaArray(delta, gtee).map((percent, idx) => (
                            context.exclusionSettings.dealLength>=idx+1&&<td key={`${idx}: Performance Lens - percentage`} className={bodyStyles+(percent<0?"text-primary-red":(percent!==Infinity?"text-primary-green":"text-black"))}>
                                {percent===Infinity?"--":(percent*100).toFixed(2)}%
                            </td>
                        ))}
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

