import React, {useContext} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { UseContextClues } from '../../context/ContextClues';

export default function CollapsibleButton({onClick, buttonTitle, addClasses, chevron, useName, useValue}) {
    const context = UseContextClues();
    return (
        <>
            <button 
                type='button' 
                name={useName} 
                value={useValue} 
                className={`w-full ${!chevron ? 'border-b' : "" } border-primary-dark hover:bg-gray-200 py-3 cursor-pointer pl-3 font-medium uppercase text-sm ${addClasses}`} 
                onClick={onClick}
                aria-label='expand and collapse menu'
                >
                    <span className="flex justify-between items-center mr-4">
                        {buttonTitle}<FontAwesomeIcon className={`text-sm transition-all duration-500 ${chevron ? "rotate-[540deg]" : ""}`} icon={faChevronUp}/> 
                    </span>
            </button>
        </>
    )
};