import react from 'react';

export default function AuxButton({toggler, onClick, handler, buttonText, width, end, disabled}){
    return <button 
        style={{width: width+"px"}} 
        className={`disabled:cursor-not-allowed ${toggler===handler?"bg-hover-blue":"bg-light-blue"} hover:bg-hover-blue  text-white border p-2 text-sm font-medium leading-5 ${end?"rounded-br-full":""}`} 
        onClick={() =>  onClick(handler)}
        disabled={disabled}>
            {buttonText}
    </button>
};